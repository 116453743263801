import React, { useState, useEffect } from 'react';
import '../styles/Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faFilm, faGear, faSignOutAlt, faBuilding, faCarBattery } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import Tooltip from '@mui/material/Tooltip';
import { Link, useNavigate } from 'react-router-dom';
import { queryOrganizationsFromCosmos } from '../utils/cosmosQueries';
import { Menu, MenuItem } from '@mui/material';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';

function Sidebar({ userName, userEmail, featureMode }) {
  const { isAuthenticated, user, logout } = useAuth0();
  const navigate = useNavigate();
  const [hoverState, setHoverState] = useState({
    plix: false,
    search: false,
    video: false,
    settings: false,
  });
  const { selectedOrg, setSelectedOrg, setDeviceFilter } = useDeviceFilter();
  const [organizations, setOrganizations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (userName.startsWith('overwatch')) {
      queryOrganizationsFromCosmos().then(setOrganizations);
    }
  }, [userName]);

  useEffect(() => {
    if (selectedOrg) {
      localStorage.setItem('selectedOrg', selectedOrg);
    }
  }, [selectedOrg]);

  const handleMouseEnter = (logo) => {
    setHoverState((prevState) => ({ ...prevState, [logo]: true }));
  };

  const handleMouseLeave = (logo) => {
    setHoverState((prevState) => ({ ...prevState, [logo]: false }));
  };

  const handleOrgClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOrgClose = () => {
    setAnchorEl(null);
  };

  const handleOrgSelect = (orgUserId) => {
    setSelectedOrg(orgUserId);
    // Reset device filter when org changes
    setDeviceFilter(null);
    localStorage.removeItem('deviceFilter');
    handleOrgClose();
  };

  const getSelectedOrgName = () => {
    const org = organizations.find((org) => org.orgUserId === selectedOrg);
    return org ? org.orgName : 'Select Organization';
  };

  return (
    <div className="sidebar">
      <Tooltip title={<h2>Live View</h2>} placement="right" arrow>
        <div
          className="logo-item"
          onMouseEnter={() => handleMouseEnter('plix')}
          onMouseLeave={() => handleMouseLeave('plix')}
          onClick={() => navigate('/')}
        >
          <Link to="/">
            <img src="/plixlogo32.png" alt="Plix Logo" />
          </Link>
        </div>
      </Tooltip>

      <Tooltip title={<h2>Dashboard</h2>} placement="right" arrow>
        <div
          className="logo-item"
          onMouseEnter={() => handleMouseEnter('dashboard')}
          onMouseLeave={() => handleMouseLeave('dashboard')}
          onClick={() => navigate('/dashboard')}
        >
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faBolt} size="2xl" className="icon-black" />
          </Link>
        </div>
      </Tooltip>

      <Tooltip title={<h2>Video History</h2>} placement="right" arrow>
        <div
          className="logo-item"
          onMouseEnter={() => handleMouseEnter('video')}
          onMouseLeave={() => handleMouseLeave('video')}
          onClick={() => navigate('/videos')}
        >
          <Link to="/videos">
            <FontAwesomeIcon icon={faFilm} size="2xl" className="icon-black" />
          </Link>
        </div>
      </Tooltip>
      {/* {isAuthenticated && featureMode === 'dev' && (
        <Tooltip title={<h2>Dashboard V2</h2>} placement="right" arrow>
          <div className="logo-item">
            <Link to="/newdashboard">
              <FontAwesomeIcon icon={faBolt} size="2xl" className="icon-black" />
            </Link>
          </div>
        </Tooltip>
      )} */}

      {isAuthenticated && featureMode === 'dev' && (
        <Tooltip title={<h2>Battery Metrics</h2>} placement="right" arrow>
          <div className="logo-item">
            <Link to="/batterymetrics">
              <FontAwesomeIcon icon={faCarBattery} size="2xl" className="icon-black" />
            </Link>
          </div>
        </Tooltip>
      )}

      <div className="logo-item-bottom">
        {isAuthenticated && userName.startsWith('overwatch') && (
          <Tooltip title={<h2>{getSelectedOrgName()}</h2>} placement="right" arrow>
            <div className="logo-item" onClick={handleOrgClick}>
              <FontAwesomeIcon icon={faBuilding} size="2xl" className="icon-black" />
            </div>
          </Tooltip>
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleOrgClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {organizations
            .sort((a, b) => a.orgName.localeCompare(b.orgName))
            .map((org) => (
              <MenuItem
                key={org.orgUserId}
                onClick={() => handleOrgSelect(org.orgUserId)}
                selected={org.orgUserId === selectedOrg}
              >
                {org.orgName}
              </MenuItem>
            ))}
        </Menu>

        {isAuthenticated && (!userName.startsWith('overwatch') || selectedOrg !== PLIX_OVERWATCH_ACCOUNT) && (
          <Tooltip title={<h2>Settings</h2>} placement="right" arrow>
            <div
              className="logo-item"
              onMouseEnter={() => handleMouseEnter('settings')}
              onMouseLeave={() => handleMouseLeave('settings')}
              onClick={() => navigate('/settings')}
            >
              <Link to="/settings">
                <FontAwesomeIcon icon={faGear} size="2xl" className="icon-black" />
              </Link>
            </div>
          </Tooltip>
        )}

        {isAuthenticated && (
          <Tooltip title={<h2>Log out</h2>} placement="right" arrow>
            <button onClick={() => logout({ returnTo: window.location.origin })} className="logout-button">
              <FontAwesomeIcon icon={faSignOutAlt} size="xl" className="icon-black" />
            </button>
          </Tooltip>
        )}

        {isAuthenticated && (
          <Tooltip title={<h2>{userEmail}</h2>} placement="right" arrow>
            <div
              className="user-info"
              onMouseEnter={() => handleMouseEnter('user')}
              onMouseLeave={() => handleMouseLeave('user')}
            >
              {userEmail.endsWith('cpgsecure.com') ? (
                <img className="user-avatar-logo" src="/logos/cpg_logo.webp"></img>
              ) : (
                <img className="user-avatar" src={user.picture} alt="User Avatar" />
              )}
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default Sidebar;

import { getCoordinatesFromHash } from './utilsGeo';

export const fetchBatteryDropTime = async (deviceId, startTime, endTime) => {
  try {
    const response = await fetch(
      `https://nginx-test.tail24705.ts.net/battery?device=eq.${deviceId}&time=gt.${startTime.toISOString()}&time=lt.${endTime.toISOString()}&level=lt.5&order=time.asc&limit=1`
    );
    const data = await response.json();
    return data.length ? new Date(data[0].time) : null;
  } catch (error) {
    console.error('Error fetching battery data:', error);
    return null;
  }
};

export const fetchTimescaleData = async (deviceId, fromDate, toDate) => {
  const batteryUrl = `https://nginx-test.tail24705.ts.net/battery?streaming_audio=eq.true&device=eq.${deviceId}&time=gte.${fromDate}&time=lt.${toDate}&level=gt.5&order=time.asc`;
  const locationUrl = `https://nginx-test.tail24705.ts.net/location?device=eq.${deviceId}&time=gte.${fromDate}&time=lt.${toDate}&order=time.asc`;
  try {
    const [batteryData, locationData] = await Promise.all([
      fetch(batteryUrl).then((res) => res.json()),
      fetch(locationUrl).then((res) => res.json()),
    ]);

    return {
      batteryData: batteryData.sort((a, b) => new Date(a.time) - new Date(b.time)),
      locationData: locationData.sort((a, b) => new Date(a.time) - new Date(b.time)),
    };
  } catch (error) {
    console.error(`Error fetching data for device ${deviceId}:`, error);
    return { batteryData: [], locationData: [] };
  }
};

export const fetchLocationAtTimestamp = async (deviceId, timestamp) => {
  const startTime = Date.now();

  // Convert timestamp to Date object
  const date = new Date(timestamp);

  // Calculate 10 minutes before and after
  const tenMinutesBefore = new Date(date.getTime() - 10 * 60000).toISOString();
  const tenMinutesAfter = new Date(date.getTime() + 10 * 60000).toISOString();

  let url = `https://nginx-test.tail24705.ts.net/device_location?device=eq.${deviceId}&time=gte.${tenMinutesBefore}&time=lte.${tenMinutesAfter}&order=time.desc&limit=1`;

  try {
    let response = await fetch(url);
    let data = await response.json();

    // If no location found in the time window, fetch the last recorded location
    if (data.length === 0) {
      url = `https://nginx-test.tail24705.ts.net/device_location?device=eq.${deviceId}&order=time.desc&limit=1`;
      response = await fetch(url);
      data = await response.json();
    }

    const endTime = Date.now();
    const latency = endTime - startTime;

    if (true || process.env.REACT_APP_PRINT_LATENCY === 'true') {
      console.log(`Latency for fetching location: ${latency}ms`);
    }

    if (data.length > 0) {
      const { latitude, longitude } = getCoordinatesFromHash(data[0].location);
      return { lat: latitude, lng: longitude };
    } else {
      return null;
    }
  } catch (error) {
    console.error(`Error fetching location for device ${deviceId} at ${timestamp}:`, error);
    return null;
  }
};

export const fetchBatteryAtTimestamp = async (deviceId, timestamp) => {
  const startTime = Date.now();

  // Convert timestamp to Date object
  const date = new Date(timestamp);

  // Calculate 25 minutes after
  const twentyFiveMinutesAfter = new Date(date.getTime() + 25 * 60000).toISOString();

  let url = `https://nginx-test.tail24705.ts.net/battery?device=eq.${deviceId}&time=lte.${twentyFiveMinutesAfter}&order=time.desc&limit=15`;

  try {
    let response = await fetch(url);
    let data = await response.json();

    const endTime = Date.now();
    const latency = endTime - startTime;

    if (true || process.env.REACT_APP_PRINT_LATENCY === 'true') {
      console.log(`Latency for fetching battery: ${latency}ms`);
    }

    if (data.length) {
      // Find the battery level closest to the timestamp
      let closest = data.reduce((prev, curr) => {
        return Math.abs(new Date(curr.time) - date) < Math.abs(new Date(prev.time) - date) ? curr : prev;
      });

      return closest.level !== undefined ? closest.level : null;
    } else {
      return null;
    }
  } catch (error) {
    console.error(`Error fetching battery for device ${deviceId} at ${timestamp}:`, error);
    return null;
  }
};

export const fetchLocationsForShift = async (deviceId, startTime, endTime) => {
  console.log('fetching locations for shift', deviceId, startTime, endTime);
  const startISO = new Date(startTime).toISOString();
  const endISO = new Date(endTime).toISOString();

  const cutoffDate = new Date('2024-09-19T04:49:15.287071+00:00');

  let url;
  if (new Date(startTime) >= cutoffDate) {
    url = `https://nginx-test.tail24705.ts.net/device_location?device=eq.${deviceId}&time=gte.${startISO}&time=lt.${endISO}&order=time.asc`;
  } else {
    url = `https://nginx-test.tail24705.ts.net/location?device=eq.${deviceId}&time=gte.${startISO}&time=lt.${endISO}&order=time.asc`;
  }

  console.log('url', url);
  try {
    let response = await fetch(url);
    let data = await response.json();
    // console.log('data', data);
    // const decoded = getCoordinatesFromHash("0101000020E610000025581CCEFC735EC01646C4DED5AA4240");
    // console.log('decoded', decoded);
    if (new Date(startTime) >= cutoffDate) {
      // Convert Geohash to latitude and longitude
      // console.log('data', data);
      data = data.map((item) => {
        const { latitude, longitude } = getCoordinatesFromHash(item.location);
        return {
          ...item,
          latitude,
          longitude,
        };
      });
    }

    console.log('locations from fetchLocationsForShift', data);
    return data;
  } catch (error) {
    console.error(`Error fetching locations for device ${deviceId} from ${startISO} to ${endISO}:`, error);
    return [];
  }
};

export const fetchGeofenceById = async (geofenceId) => {
  const timescaleBaseUrl = process.env.REACT_APP_TIMESCALE_BASE_URL;
  const url = `${timescaleBaseUrl}/geofences?id=eq.${geofenceId}`;

  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_TIMESCALE_API_KEY}`,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (data.length > 0) {
      const geofence = data[0];
      const { longitude, latitude } = getCoordinatesFromHash(geofence.center);
      return {
        ...geofence,
        latitude,
        longitude,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error(`Error fetching geofence with id ${geofenceId}:`, error);
    return null;
  }
};

export const fetchGeofencesForOrganization = async (organizationId) => {
  const startTime = Date.now();
  const timescaleBaseUrl = process.env.REACT_APP_TIMESCALE_BASE_URL;

  let url = `${timescaleBaseUrl}/geofences`;
  if (organizationId) {
    url += `?organization=eq.${organizationId}`;
  }
  console.log('Fetching geofences from URL:', url);

  try {
    console.log('Making fetch request...');
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_TIMESCALE_API_KEY}`,
      },
    });

    console.log('Response status:', response.status);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Raw data received:', data);

    const endTime = Date.now();
    const latency = endTime - startTime;
    console.log(`Latency for fetching geofences: ${latency}ms`);

    if (data.length === 0) {
      console.log('No geofences found for this organization');
    }

    const processedData = data.map((geofence) => {
      const { longitude, latitude } = getCoordinatesFromHash(geofence.center);
      return {
        ...geofence,
        latitude,
        longitude,
      };
    });

    console.log('Processed geofences:', processedData);
    return processedData;
  } catch (error) {
    console.error(`Error fetching geofences:`, error);
    return [];
  }
};

export const deleteGeofence = async (id, organization) => {
  if (!id || !organization) {
    console.error('Error: id and organization are required fields');
    return { success: false, error: 'id and organization are required fields' };
  }

  const url = 'https://geofence.plix.ai/delete_geofence';

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        organization,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || `HTTP error! status: ${response.status}`);
    }

    console.log('Geofence deleted successfully:', data.message);
    return { success: true, message: data.message };
  } catch (error) {
    console.error('Error deleting geofence:', error);
    return { success: false, error: error.message };
  }
};

export const addGeofence = async (name, organization, center, radius, nickname) => {
  if (!name || !organization || !center || !radius) {
    console.error('Error: name, organization, center, and radius are required fields');
    return { success: false, error: 'name, organization, center, and radius are required fields' };
  }

  const url = 'https://geofence.plix.ai/create_geofence';

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        organization,
        center,
        radius,
        nickname,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || `HTTP error! status: ${response.status}`);
    }

    console.log('Geofence added successfully:', data.message);
    return { success: true, message: data.message };
  } catch (error) {
    console.error('Error adding geofence:', error);
    return { success: false, error: error.message };
  }
};

export const updateGeofence = async (id, organization, newName, center, radius) => {
  if (!id || !organization) {
    console.error('Error: id and organization are required fields');
    return { success: false, error: 'id and organization are required fields' };
  }

  const url = 'https://geofence.plix.ai/update_geofence';

  const updateData = {
    id,
    organization,
  };

  if (newName !== undefined) updateData.nickname = newName;
  if (center !== undefined) updateData.center = center;
  if (radius !== undefined) updateData.radius = radius;

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || `HTTP error! status: ${response.status}`);
    }

    console.log('Geofence updated successfully:', data.message);
    return { success: true, message: data.message };
  } catch (error) {
    console.error('Error updating geofence:', error);
    return { success: false, error: error.message };
  }
};

export const fetchBatteryRecordingStatus = async (deviceId, startTime, endTime, escalationTimeSpans) => {
  try {
    const timescaleBaseUrl = process.env.REACT_APP_TIMESCALE_BASE_URL;
    let baseUrl = `${timescaleBaseUrl}/battery?device=eq.${deviceId}&time=gte.${startTime}&time=lte.${endTime}&recording_audio=eq.true`;

    // Add conditions to exclude escalation time spans
    if (escalationTimeSpans.length > 0) {
      const exclusionConditions = escalationTimeSpans.map(
        (span, index) => `or=(and(time.lt.${span.start.toISOString()},time.gt.${span.end.toISOString()}))`
      );
      baseUrl += `&${exclusionConditions.join('&')}`;
    }

    // Add ordering and limit
    baseUrl += '&order=time.desc&limit=1';
    const response = await fetch(baseUrl, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TIMESCALE_API_KEY}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.length > 0;
  } catch (error) {
    console.error('Error fetching battery recording status:', error);
    return false;
  }
};

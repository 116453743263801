import React, { useState, useEffect } from 'react';
import '../styles/Configuration.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faWalkieTalkie, faSave, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { cosmosClient } from '../utils/eventstorage';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';
import { updateDeviceConfig } from '../utils/deviceConfig';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Material-UI components
import {
  Autocomplete,
  TextField,
  Button,
  Modal,
  Box,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Switch,
  Typography,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  FormGroup,
  CircularProgress,
  IconButton,
  Snackbar,
} from '@mui/material';

// Material-UI icons
import {
  Info as InfoIcon,
  Warning as WarningIcon,
  RestartAlt as RestartAltIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

// Material-UI styled components
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';

dayjs.extend(utc);
dayjs.extend(timezone);

const RedSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#ff0000',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.08)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#ff0000',
  },
}));

// Add this custom styled Tooltip
const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      fontSize: 14,
      border: '1px solid #dadde9',
      maxWidth: 320,
      padding: theme.spacing(2),
      '& b': {
        fontWeight: 600,
      },
    },
  })
);

dayjs.extend(utc);
dayjs.extend(timezone);

const Configuration = ({ userId, retentionPeriodDays, featureMode, isOverwatch }) => {
  const { selectedOrg } = useDeviceFilter();
  const getEffectiveUserId = () => (isOverwatch ? selectedOrg : userId);
  const [triggers, setTriggers] = useState([]);
  const [errors, setErrors] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [liveViewPolicy, setLiveViewPolicy] = useState('During Shift');
  const [recordingPolicy, setRecordingPolicy] = useState('User');
  const [consentModalOpen, setConsentModalOpen] = useState(false);
  const [pendingSave, setPendingSave] = useState(false);
  const [initialPhoneNumbers, setInitialPhoneNumbers] = useState([]);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [reportFrequency, setReportFrequency] = useState('weekly');
  const [reportHour, setReportHour] = useState('5');
  const [reportMinute, setReportMinute] = useState('0');
  const [reportAmPm, setReportAmPm] = useState('PM');
  const [timezone, setTimezone] = useState('');
  const [devices, setDevices] = useState([]);
  const [pendingDeviceChanges, setPendingDeviceChanges] = useState({});
  const [orderBy, setOrderBy] = useState('assignedTo');
  const [order, setOrder] = useState('asc');
  const [globalDeviceMode, setGlobalDeviceMode] = useState('normal');
  const [globalRecordingPolicy, setGlobalRecordingPolicy] = useState('User');
  const [applyGlobalDeviceMode, setApplyGlobalDeviceMode] = useState(false);
  const [applyGlobalRecordingPolicy, setApplyGlobalRecordingPolicy] = useState(false);
  const [restartingDevices, setRestartingDevices] = useState({});
  const [editingDevice, setEditingDevice] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [pendingNameChanges, setPendingNameChanges] = useState({});

  console.log('featureMode', featureMode);

  useEffect(() => {
    const getTimezone = () => {
      const formatter = new Intl.DateTimeFormat();
      const timezone = formatter.resolvedOptions().timeZone;
      console.log('timezone', timezone);
      setTimezone(timezone);
    };

    getTimezone();
  }, []);

  const inputStyle = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
    },
  };

  const chipStyle = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
    },
  };

  const timeBoxStyle = {
    display: 'flex',
    border: '1px solid #ccc',
    borderRadius: '9999px', // This creates a pill shape
    overflow: 'hidden',
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
    '& .MuiSelect-select': {
      paddingRight: '24px',
    },
    '& .MuiSelect-icon': {
      right: '0',
    },
  };

  const timeSelectStyle = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const effectiveUserId = getEffectiveUserId();
        const devicesContainer = cosmosClient.database('Devices').container('Devices');
        const { resources: fetchedDevices } = await devicesContainer.items
          .query({
            query: 'SELECT * FROM c WHERE c.userId = @userId',
            parameters: [{ name: '@userId', value: effectiveUserId }],
          })
          .fetchAll();

        setDevices(fetchedDevices);

        if (fetchedDevices.length > 0) {
          const firstDevice = fetchedDevices[0];
          const keywords = (firstDevice.activationKeyword || '').split(',').filter(Boolean);
          const phoneNumbers = (firstDevice.notificationPhoneNumber || '').split(',');

          const initialTriggers = keywords.map((keyword, index) => ({
            condition: keyword.trim(),
            actions: {
              startRecording: true,
              generateEscalation: true,
              sendWhatsApp: phoneNumbers[index] ? true : false,
            },
            phoneNumber: phoneNumbers[index]
              ? `(${phoneNumbers[index].slice(0, 3)}) ${phoneNumbers[index].slice(3, 6)}-${phoneNumbers[index].slice(6)}`
              : '',
          }));

          setTriggers(initialTriggers);
          setErrors(initialTriggers.map(() => ({ condition: '', actions: {}, phoneNumber: '' })));
          setLiveViewPolicy(firstDevice.liveViewPolicy || 'During Shift');
          setInitialPhoneNumbers(phoneNumbers);
          setRecordingPolicy(firstDevice.recordingPolicy || 'User');
        }

        // Fetch organization
        const orgContainer = cosmosClient.database('Organizations').container('Organizations');
        const { resources: orgs } = await orgContainer.items
          .query({
            query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
            parameters: [{ name: '@userId', value: userId }],
          })
          .fetchAll();

        if (orgs.length > 0) {
          const org = orgs[0];
          const emailReportPolicy = org.emailReportPolicy || {};
          setEmailAddresses(emailReportPolicy.addresses || []);
          setReportFrequency(emailReportPolicy.frequency || 'weekly');
          setTimezone(emailReportPolicy.timezone || '');

          if (emailReportPolicy.timeOfDay) {
            const [hour, minute] = emailReportPolicy.timeOfDay.split(':');
            if (parseInt(hour) > 12) {
              setReportHour(parseInt(hour) - 12);
              setReportAmPm('PM');
            } else {
              if (parseInt(hour) == 0) {
                setReportHour(12);
              } else {
                setReportHour(parseInt(hour));
              }
              setReportAmPm('AM');
            }
            setReportMinute(parseInt(minute));
          }
        }
      } catch (error) {
        console.error('Failed to fetch initial data:', error);
      }
    };

    fetchInitialData();
  }, [userId, selectedOrg]);

  const handleAddTrigger = () => {
    setTriggers([
      ...triggers,
      {
        condition: '',
        actions: { startRecording: true, generateEscalation: true, sendWhatsApp: false },
        phoneNumber: '',
      },
    ]);
    setErrors([...errors, { condition: '', actions: {}, phoneNumber: '' }]);
    setUnsavedChanges(true);
  };

  const handleTriggerChange = (index, field, value) => {
    const newTriggers = [...triggers];
    if (field === 'actions') {
      newTriggers[index][field] = value;
    } else {
      newTriggers[index][field] = value || '';
    }
    setTriggers(newTriggers);

    const newErrors = [...errors];
    newErrors[index][field] = validateField(field, value, newTriggers[index]);
    setErrors(newErrors);
    setUnsavedChanges(true);
  };

  const validateField = (field, value, trigger) => {
    switch (field) {
      case 'condition':
        if (!value || !value.trim()) return 'Trigger phrase is required';
        if (value.trim().length < 4) return 'Trigger phrase must be at least 4 characters long';
        if (value.includes('Wolverines')) return 'Invalid sports team to support';
        if (value.trim().split(' ').length > 2 || value.length > 50) return 'Must be at most 2 words and 50 characters';
        break;
      case 'actions':
        if (
          !value ||
          (typeof value === 'object' && !value.startRecording && !value.generateEscalation && !value.sendWhatsApp)
        )
          return 'At least one action is required';
        break;
      case 'phoneNumber':
        if (trigger && trigger.actions.sendWhatsApp && (!value || !value.trim())) return 'Phone number is required';
        const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
        if (value && !phonePattern.test(value)) return 'Valid phone number required.';
        break;
      default:
        break;
    }
    return '';
  };

  const handleRemoveTrigger = (index) => {
    const newTriggers = triggers.filter((_, i) => i !== index);
    setTriggers(newTriggers);
    const newErrors = errors.filter((_, i) => i !== index);
    setErrors(newErrors);
    setUnsavedChanges(true);
    updateDeviceKeywords(newTriggers);
  };

  const updateDeviceKeywords = async (newTriggers) => {
    const activationKeywords = newTriggers
      .map((trigger) => trigger.condition.trim())
      .filter(Boolean)
      .join(',');

    const notificationPhoneNumbers = newTriggers
      .map((trigger) => (trigger.actions.sendWhatsApp ? trigger.phoneNumber.replace(/[^\d]/g, '') : ''))
      .filter(Boolean)
      .join(',');

    const devicesContainer = cosmosClient.database('Devices').container('Devices');
    const orgContainer = cosmosClient.database('Organizations').container('Organizations');

    try {
      // Fetch devices
      const effectiveUserId = getEffectiveUserId();
      const { resources: devices } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.userId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      // Fetch organization
      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];

      // Update devices
      const updateDevicePromises = devices.map(async (device) => {
        const updatedDevice = {
          ...device,
          liveViewPolicy,
          // Use the device's existing recordingPolicy instead of a global one
          recordingPolicy: device.recordingPolicy || 'User',
          activationKeyword: activationKeywords || undefined,
          notificationPhoneNumber: notificationPhoneNumbers || undefined,
        };

        try {
          await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);
          console.log(`Device ${device.deviceId} updated successfully`);
        } catch (error) {
          if (error.code === 404) {
            console.warn(`Device ${device.deviceId} not found, skipping update`);
          } else {
            throw error;
          }
        }
      });

      // Update organization
      const updatedOrg = {
        ...org,
        liveViewPolicy,
        // Remove recordingPolicy from organization level
        activationKeyword: activationKeywords || undefined,
        notificationPhoneNumber: notificationPhoneNumbers || undefined,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      await Promise.all(updateDevicePromises);

      console.log('Device keywords, policies, and organization updated successfully');
      setInitialPhoneNumbers(notificationPhoneNumbers.split(',').filter(Boolean));
    } catch (error) {
      console.error('Failed to update device keywords, policies, and organization:', error);
      throw error;
    }
  };

  const handleSaveTrigger = async () => {
    const newPhoneNumbers = triggers
      .filter((trigger) => trigger.actions.sendWhatsApp)
      .map((trigger) => trigger.phoneNumber.replace(/[^\d]/g, ''));

    const hasNewPhoneNumber = newPhoneNumbers.some((number) => !initialPhoneNumbers.includes(number));

    if (!liveViewPolicy || !recordingPolicy) {
      setErrors((prevErrors) =>
        prevErrors.map((error) => ({
          ...error,
          liveViewPolicy: !liveViewPolicy ? 'Live View policy is required' : error.liveViewPolicy,
          recordingPolicy: !recordingPolicy ? 'Recording policy is required' : error.recordingPolicy,
        }))
      );
      return;
    }

    const newErrors = triggers.map((trigger) => ({
      condition: validateField('condition', trigger.condition),
      actions: {
        startRecording: validateField('actions', trigger.actions.startRecording ? 'startRecording' : ''),
        generateEscalation: validateField('actions', trigger.actions.generateEscalation ? 'generateEscalation' : ''),
        sendWhatsApp: validateField('actions', trigger.actions.sendWhatsApp ? 'sendWhatsApp' : ''),
      },
      phoneNumber: validateField('phoneNumber', trigger.phoneNumber, trigger),
    }));

    setErrors(newErrors);

    const hasErrors = newErrors.some(
      (error, index) =>
        error.condition ||
        error.actions.startRecording ||
        error.actions.generateEscalation ||
        (triggers[index].actions.sendWhatsApp && error.phoneNumber)
    );

    if (hasErrors) {
      return;
    }

    if (hasNewPhoneNumber) {
      setConsentModalOpen(true);
      setPendingSave(true);
      return;
    }

    try {
      const effectiveUserId = getEffectiveUserId();
      await updateDeviceKeywords(triggers);
      await updateEmailReportPolicy(effectiveUserId);

      const changedDevices = [];
      if (Object.keys(pendingDeviceChanges).length > 0 || Object.keys(pendingNameChanges).length > 0) {
        await updateDevices(effectiveUserId);
        changedDevices.push(...Object.keys(pendingDeviceChanges), ...Object.keys(pendingNameChanges));
      }
      console.log('changedDevices: ', changedDevices);
      setUnsavedChanges(false);
      setPendingDeviceChanges({});
      setPendingNameChanges({});

      // After all Cosmos DB updates are complete, make the API call only for changed devices
      for (const deviceId of changedDevices) {
        try {
          const response = await fetch('https://utils.plix.ai/v1/updateconfig', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ deviceId: deviceId }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const result = await response.json();
          console.log(`Configuration update triggered for device ${deviceId}:`, result);
        } catch (error) {
          console.error(`Failed to trigger configuration update for device ${deviceId}:`, error);
        }
      }

      // Set save success message
      setSaveSuccess(true);
      // Hide success message after 3 seconds
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (error) {
      console.error('Failed to save configuration:', error);
      // You might want to show an error message to the user here
    }
  };

  const completeSave = async () => {
    if (
      triggers.every(
        (trigger) =>
          trigger.condition &&
          trigger.actions.startRecording &&
          trigger.actions.generateEscalation &&
          (!trigger.actions.sendWhatsApp || (trigger.actions.sendWhatsApp && trigger.phoneNumber))
      )
    ) {
      try {
        await updateDeviceKeywords(triggers);
        setUnsavedChanges(false);
        // Set save success message
        setSaveSuccess(true);
        // Hide success message after 3 seconds
        setTimeout(() => setSaveSuccess(false), 3000);
      } catch (error) {
        console.error('Failed to save configuration:', error);
        // You might want to show an error message to the user here
      }
    } else {
      const newErrors = triggers.map((trigger) => ({
        condition: validateField('condition', trigger.condition),
        actions: {
          startRecording: validateField('actions', trigger.actions.startRecording ? 'startRecording' : ''),
          generateEscalation: validateField('actions', trigger.actions.generateEscalation ? 'generateEscalation' : ''),
          sendWhatsApp: validateField('actions', trigger.actions.sendWhatsApp ? 'sendWhatsApp' : ''),
        },
        phoneNumber: validateField('phoneNumber', trigger.phoneNumber, trigger),
      }));
      setErrors(newErrors);
    }
    setPendingSave(false);
  };

  const handleConsentAgree = () => {
    setConsentModalOpen(false);
    completeSave();
  };

  const handleConsentCancel = () => {
    setConsentModalOpen(false);
    setPendingSave(false);
  };

  const handlePolicyChange = (deviceId, policyType, newValue) => {
    setPendingDeviceChanges((prev) => ({
      ...prev,
      [deviceId]: {
        ...prev[deviceId],
        [policyType]: newValue,
      },
    }));
    setUnsavedChanges(true);
  };

  const handleAddEmail = () => {
    if (newEmail && !emailAddresses.includes(newEmail)) {
      setEmailAddresses([...emailAddresses, newEmail]);
      setNewEmail('');
      setUnsavedChanges(true);
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmailAddresses(emailAddresses.filter((email) => email !== emailToRemove));
    setUnsavedChanges(true);
  };

  const handleFrequencyChange = (event) => {
    setReportFrequency(event.target.value);
    setUnsavedChanges(true);
  };

  const handleHourChange = (event) => {
    setReportHour(event.target.value);
    setUnsavedChanges(true);
  };

  const handleMinuteChange = (event) => {
    setReportMinute(event.target.value);
    setUnsavedChanges(true);
  };

  const handleAmPmChange = (event) => {
    setReportAmPm(event.target.value);
    setUnsavedChanges(true);
  };

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
    setUnsavedChanges(true);
  };

  const updateEmailReportPolicy = async (effectiveUserId) => {
    const orgContainer = cosmosClient.database('Organizations').container('Organizations');

    try {
      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      let timeHr;
      let timeMin;
      if (reportAmPm == 'AM') {
        timeHr = reportHour == 12 ? 0 : reportHour;
      } else {
        timeHr = reportHour == 12 ? 12 : reportHour + 12;
      }
      timeMin = reportMinute;

      const updatedOrg = {
        ...org,
        emailReportPolicy: {
          addresses: emailAddresses,
          frequency: reportFrequency,
          timeOfDay: `${timeHr}:${timeMin}`,
          timezone: timezone,
          isSubscribed: emailAddresses.length > 0, // Set isSubscribed based on email count
        },
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);
      console.log('Email report policy updated successfully');
    } catch (error) {
      console.error('Failed to update email report policy:', error);
      throw error;
    }
  };

  const handleDeviceModeChange = (deviceId, newMode) => {
    setPendingDeviceChanges((prev) => ({
      ...prev,
      [deviceId]: { ...prev[deviceId], deviceMode: newMode },
    }));
    setUnsavedChanges(true);
  };

  const updateDevices = async (effectiveUserId) => {
    const devicesContainer = cosmosClient.database('Devices').container('Devices');

    const updatePromises = devices.map(async (device) => {
      const changes = {
        ...pendingDeviceChanges[device.deviceId],
        ...(pendingNameChanges[device.deviceId] && { assignedTo: pendingNameChanges[device.deviceId] }),
      };

      // Only update if there are actual changes
      if (Object.keys(changes).length > 0) {
        const updatedDevice = {
          ...device,
          ...changes,
          // Only update these fields if deviceMode has changed
          ...(changes.deviceMode && {
            TTSEnabled: changes.deviceMode !== 'stealth',
            escalationCueEnabled: changes.deviceMode === 'deterrent',
            recBeep: changes.deviceMode === 'deterrent',
          }),
          // These fields remain unchanged unless explicitly modified
          hapticEnabled: device.hapticEnabled,
          screenDim: device.screenDim,
        };

        try {
          await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);
          console.log(`Device ${device.deviceId} updated successfully`);
        } catch (error) {
          console.error(`Failed to update device ${device.deviceId}:`, error);
          throw error;
        }
      }
    });

    await Promise.all(updatePromises);

    // Update local state only for devices with changes
    setDevices((prevDevices) =>
      prevDevices.map((device) => {
        const changes = {
          ...pendingDeviceChanges[device.deviceId],
          ...(pendingNameChanges[device.deviceId] && { assignedTo: pendingNameChanges[device.deviceId] }),
        };
        return Object.keys(changes).length > 0 ? { ...device, ...changes } : device;
      })
    );
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedDevices = React.useMemo(() => {
    const comparator = (a, b) => {
      const aValue = a[orderBy] || a.deviceName || a.deviceId;
      const bValue = b[orderBy] || b.deviceName || b.deviceId;
      if (order === 'asc') {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    };
    return [...devices].sort(comparator);
  }, [devices, order, orderBy]);

  // Add a check at the beginning of the component to prevent rendering if selectedOrg is PLIX_OVERWATCH_ACCOUNT
  if (isOverwatch && selectedOrg === PLIX_OVERWATCH_ACCOUNT) {
    return <div>Configuration is not available for the Overwatch account.</div>;
  }

  const hours = Array.from({ length: 12 }, (_, i) => i + 1);
  const minutes = Array.from({ length: 12 }, (_, i) => i * 5);
  const commonTimezones = [
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'Europe/London',
    'Europe/Paris',
    'Asia/Tokyo',
    'Australia/Sydney',
    // Add more as needed
  ];

  const handleGlobalDeviceModeChange = (event) => {
    setGlobalDeviceMode(event.target.value);
    if (applyGlobalDeviceMode) {
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          deviceMode: event.target.value,
        };
      });
      setPendingDeviceChanges(newChanges);
    }
  };

  const handleGlobalRecordingPolicyChange = (event) => {
    setGlobalRecordingPolicy(event.target.value);
    if (applyGlobalRecordingPolicy) {
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          recordingPolicy: event.target.value,
        };
      });
      setPendingDeviceChanges(newChanges);
    }
  };

  const handleApplyGlobalDeviceMode = (event) => {
    setApplyGlobalDeviceMode(event.target.checked);
    if (event.target.checked) {
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          deviceMode: globalDeviceMode,
        };
      });
      setPendingDeviceChanges(newChanges);
    } else {
      // Reset Device Mode to original values
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          deviceMode: device.deviceMode || 'normal',
        };
      });
      setPendingDeviceChanges(newChanges);
    }
  };

  const handleApplyGlobalRecordingPolicy = (event) => {
    setApplyGlobalRecordingPolicy(event.target.checked);
    if (event.target.checked) {
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          recordingPolicy: globalRecordingPolicy,
        };
      });
      setPendingDeviceChanges(newChanges);
    } else {
      // Reset Recording Policy to original values
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          recordingPolicy: device.recordingPolicy || 'User',
        };
      });
      setPendingDeviceChanges(newChanges);
    }
  };

  const getRestartButtonTooltip = (deviceId) => {
    if (restartingDevices[deviceId] === 'loading') {
      return 'Restarting...';
    } else if (restartingDevices[deviceId] === 'completed') {
      return 'Restart initiated';
    } else if (restartingDevices[deviceId]) {
      const timeLeft = Math.ceil((300000 - (Date.now() - restartingDevices[deviceId])) / 60000);
      return `Wait ${timeLeft} minute${timeLeft !== 1 ? 's' : ''} before restarting again`;
    }
    return 'Restart device';
  };

  const handleRestartDevice = async (deviceId) => {
    setRestartingDevices((prev) => ({ ...prev, [deviceId]: 'loading' }));

    // Show loading state briefly
    setTimeout(() => {
      setRestartingDevices((prev) => ({ ...prev, [deviceId]: 'completed' }));
    }, 500); // Show loading for 500ms

    try {
      const response = await fetch('https://utils.plix.ai/v1/restartdevice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ deviceId: deviceId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(`Device ${deviceId} restart triggered:`, result);
    } catch (error) {
      console.error(`Failed to restart device ${deviceId}:`, error);
      // Even if there's an error, we keep the checkmark displayed
    }

    // Reset the button after 5 minutes
    setTimeout(() => {
      setRestartingDevices((prev) => {
        const updated = { ...prev };
        delete updated[deviceId];
        return updated;
      });
    }, 300000); // 5 minutes
  };

  const handleEditStart = (device) => {
    setEditingDevice(device.deviceId);
    setEditValues({
      ...editValues,
      [device.deviceId]: device.assignedTo || device.deviceName || device.deviceId,
    });
  };

  const handleEditChange = (deviceId, value) => {
    setEditValues({
      ...editValues,
      [deviceId]: value,
    });
  };

  const handleEditCancel = (deviceId) => {
    setEditingDevice(null);
    setEditValues({
      ...editValues,
      [deviceId]:
        devices.find((d) => d.deviceId === deviceId).assignedTo ||
        devices.find((d) => d.deviceId === deviceId).deviceName ||
        deviceId,
    });
  };

  const handleEditSave = (device) => {
    const newValue = editValues[device.deviceId];
    setPendingNameChanges({
      ...pendingNameChanges,
      [device.deviceId]: newValue,
    });
    setEditingDevice(null);
    setUnsavedChanges(true);
  };

  return (
    <div className="configuration-wrapper">
      <div className="configuration-header">
        <div className="configuration-header-left">
          <h1>Configuration</h1>
        </div>
        <div className="configuration-header-right">
          {unsavedChanges && <span className="unsaved-changes-message show">Unsaved changes!</span>}
          {saveSuccess && <span className="save-success-message show">Successfully saved!</span>}
          <button
            onClick={handleSaveTrigger}
            className={`save-button ${!unsavedChanges ? 'disabled' : ''}`}
            disabled={!unsavedChanges}
          >
            <FontAwesomeIcon icon={faSave} className="button-icon" />
            Save
          </button>
        </div>
      </div>
      <div className="configuration-container">
        <div className="configuration-section">
          <h2>Device Settings</h2>
          {/* <div className="policy-description">
            <span>Configure your devices here.</span>
          </div> */}
          {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <FormControl sx={{ width: '250px' }}>
                <FormLabel>
                  <Switch
                    checked={applyGlobalDeviceMode}
                    onChange={handleApplyGlobalDeviceMode}
                    name="applyGlobalDeviceMode"
                  />
                  Global Device Mode
                </FormLabel>
                <Select
                  value={globalDeviceMode}
                  onChange={handleGlobalDeviceModeChange}
                  disabled={!applyGlobalDeviceMode}
                  sx={{ width: '200px' }}
                >
                  <MenuItem value="normal">Normal</MenuItem>
                  <MenuItem value="stealth">Stealth</MenuItem>
                  <MenuItem value="deterrent">Deterrent</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <FormControl sx={{ width: '250px' }}>
                <FormLabel>
                  <Switch
                    checked={applyGlobalRecordingPolicy}
                    onChange={handleApplyGlobalRecordingPolicy}
                    name="applyGlobalRecordingPolicy"
                  />
                  Global Recording Policy
                </FormLabel>
                <Select
                  value={globalRecordingPolicy}
                  onChange={handleGlobalRecordingPolicyChange}
                  disabled={!applyGlobalRecordingPolicy}
                  sx={{ width: '200px' }}
                >
                  <MenuItem value="User">User</MenuItem>
                  <MenuItem value="Always">Always</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box> */}
          {/* <br></br> */}
          <Paper style={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer
              style={{
                maxHeight: '400px',
                overflow: 'auto',
              }}
            >
              <Table stickyHeader sx={{ minWidth: 650, tableLayout: 'fixed' }} aria-label="device settings table">
                <colgroup>
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '25%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '15%' }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', width: '20%' }}>
                      <TableSortLabel
                        active={orderBy === 'deviceId'}
                        direction={orderBy === 'deviceId' ? order : 'asc'}
                        onClick={() => handleRequestSort('deviceId')}
                      >
                        Device ID
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', width: '25%' }}>
                      <TableSortLabel
                        active={orderBy === 'assignedTo'}
                        direction={orderBy === 'assignedTo' ? order : 'asc'}
                        onClick={() => handleRequestSort('assignedTo')}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          Owner
                          {/* <EditIcon style={{ marginLeft: '5px', fontSize: '0.8rem', color: '#666' }} /> */}
                        </div>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', width: '20%' }}>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography variant="body2" component="div">
                              <b>Balanced Mode:</b> Standard device operation with audio feedback.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Mode
                          <InfoIcon style={{ fontSize: 'small', marginLeft: '5px' }} />
                        </div>
                      </StyledTooltip>
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', width: '20%' }}>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography variant="body2" component="div">
                              <b>User:</b> Video recording will only start when manually triggered by the user.
                              <br />
                              <b>Shift:</b> Video recording will start automatically when the devices are in shift.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Recording Policy
                          <InfoIcon style={{ fontSize: 'small', marginLeft: '5px' }} />
                        </div>
                      </StyledTooltip>
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', width: '15%' }}>
                      <StyledTooltip
                        title={
                          <React.Fragment>
                            <Typography variant="body2" component="div">
                              <WarningIcon
                                style={{
                                  fontSize: 'small',
                                  margin: '0px',
                                  padding: '0px',
                                  marginRight: '5px',
                                  marginBottom: '-1px',
                                }}
                              />{' '}
                              Restarting a device interrupts in-progress recordings.
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Restart
                          <InfoIcon style={{ fontSize: 'small', marginLeft: '5px' }} />
                        </div>
                      </StyledTooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedDevices.map((device) => (
                    <TableRow key={device.deviceId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row" align="left" style={{ width: '20%', padding: '8px 16px' }}>
                        {device.deviceId}
                      </TableCell>
                      <TableCell align="left" style={{ width: '25%', padding: '8px 16px' }}>
                        <div style={{ position: 'relative', width: '100%', display: 'flex', alignItems: 'center' }}>
                          <TextField
                            value={
                              editValues[device.deviceId] ||
                              pendingNameChanges[device.deviceId] ||
                              device.assignedTo ||
                              device.deviceName ||
                              device.deviceId
                            }
                            onChange={(e) => handleEditChange(device.deviceId, e.target.value)}
                            onFocus={() => handleEditStart(device)}
                            size="small"
                            fullWidth
                            sx={{
                              '& .MuiInputBase-root': {
                                width: '100%',
                                paddingRight: editingDevice === device.deviceId ? '60px' : '14px',
                              },
                              '& .MuiInputBase-input': {
                                textOverflow: 'ellipsis',
                              },
                            }}
                            inputProps={{
                              style: {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              },
                            }}
                          />
                          {editingDevice === device.deviceId && (
                            <div
                              style={{
                                position: 'absolute',
                                right: '8px',
                                display: 'flex',
                                gap: '4px',
                              }}
                            >
                              <IconButton onClick={() => handleEditSave(device)} size="small" sx={{ padding: '2px' }}>
                                <CheckIcon fontSize="small" sx={{ fontSize: '1rem' }} />
                              </IconButton>
                              <IconButton
                                onClick={() => handleEditCancel(device.deviceId)}
                                size="small"
                                sx={{ padding: '2px' }}
                              >
                                <CloseIcon fontSize="small" sx={{ fontSize: '1rem' }} />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="center" style={{ width: '20%' }}>
                        <FormControl sx={{ minWidth: 120 }}>
                          <Select value="normal" disabled={true} displayEmpty>
                            <MenuItem value="normal">Balanced</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center" style={{ width: '20%' }}>
                        <FormControl sx={{ minWidth: 120 }}>
                          <Select
                            value={
                              pendingDeviceChanges[device.deviceId]?.recordingPolicy || device.recordingPolicy || 'User'
                            }
                            onChange={(event) =>
                              handlePolicyChange(device.deviceId, 'recordingPolicy', event.target.value)
                            }
                            displayEmpty
                          >
                            <MenuItem value="User">User</MenuItem>
                            <MenuItem value="Shift">Shift</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center" style={{ width: '15%' }}>
                        <Tooltip title={getRestartButtonTooltip(device.deviceId)} arrow>
                          <span
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                          >
                            <IconButton
                              onClick={() => handleRestartDevice(device.deviceId)}
                              disabled={!!restartingDevices[device.deviceId]}
                              sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                },
                                '&.Mui-disabled': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                  color:
                                    restartingDevices[device.deviceId] === 'completed'
                                      ? '#4caf50'
                                      : 'rgba(0, 0, 0, 0.26)',
                                },
                                color: '#ff0000',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 0,
                              }}
                            >
                              {restartingDevices[device.deviceId] === 'loading' ? (
                                <CircularProgress size={24} color="inherit" />
                              ) : restartingDevices[device.deviceId] === 'completed' ? (
                                <CheckCircleOutlineIcon sx={{ fontSize: '1.5rem' }} />
                              ) : (
                                <RestartAltIcon sx={{ fontSize: '1.5rem' }} />
                              )}
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {/* Comment out the description under the table
          <div className="option-description" style={{ marginTop: '1rem' }}>
            <h3>Device Mode:</h3>
            <strong>Normal Mode:</strong> Standard device operation with audio feedback.<br />
            <strong>Stealth Mode:</strong> Quiet operation with minimal visual and audio cues.<br />
            <strong>Deterrent Mode:</strong> Enhanced audio and visual cues for maximum visibility.<br />
            <h3>Recording Policy:</h3>
            <strong>User:</strong> Recording starts when triggered by the user or a trigger phrase.<br />
            <strong>Always:</strong> The device is always recording.
          </div>
          */}

          <br></br>
          <br></br>
        </div>
        <div className="configuration-section">
          <h2>Trigger Phrases</h2>
          <div className="trigger-info">
            <FontAwesomeIcon icon={faWalkieTalkie} className="trigger-icon" />
            <span>Trigger an escalation and start recording when a user says a particular phrase.</span>
          </div>
          <div className="triggers-list">
            {triggers.map((trigger, index) => (
              <div key={index} className="trigger-row">
                <Autocomplete
                  freeSolo
                  options={['Code Red', 'Code Pink', 'Code Blue', 'Code Green', 'Code Yellow']}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={!!errors[index]?.condition}
                      helperText={errors[index]?.condition}
                    />
                  )}
                  value={trigger.condition || ''}
                  onChange={(event, newValue) => handleTriggerChange(index, 'condition', newValue)}
                  inputValue={trigger.condition || ''}
                  onInputChange={(event, newInputValue) => handleTriggerChange(index, 'condition', newInputValue)}
                  className="trigger-input"
                />
                <FontAwesomeIcon
                  icon={faTrash}
                  className="trigger-delete-icon"
                  onClick={() => handleRemoveTrigger(index)}
                />
              </div>
            ))}
          </div>
          <div className="add-trigger-wrapper">
            <Button variant="contained" color="primary" onClick={handleAddTrigger} className="add-trigger-button">
              <FontAwesomeIcon icon={faPlus} className="button-icon" />
              Add Trigger Phrase
            </Button>
            <span className="trigger-warning">Avoid using common words as trigger phrases.</span>
          </div>
        </div>
        <br></br>
        {/* <div className="configuration-section">
          <h2>Video History Settings</h2>
          <div className="policy-container">
            <div className="policy-dropdown">
              <label>Retention Period</label>
              <div className="policy-description">
                <span>Number of days video recordings will be stored before being deleted.</span>
              </div>
              <FormControl component="fieldset">
                <RadioGroup aria-label="retention-policy" name="retention-policy" value={retentionPeriodDays}>
                  <FormControlLabel
                    value={30}
                    control={<Radio />}
                    label="30 days"
                    disabled={retentionPeriodDays !== 30}
                  />
                  <FormControlLabel
                    value={90}
                    control={<Radio />}
                    label="90 days"
                    disabled={retentionPeriodDays !== 90}
                  />
                  <FormControlLabel
                    value={100000}
                    control={<Radio />}
                    label="Unlimited"
                    disabled={retentionPeriodDays !== 100000}
                  />
                </RadioGroup>
              </FormControl>
              <div className="option-description">
                <span>
                  {retentionPeriodDays >= 100000
                    ? 'Video recordings will be retained indefinitely.'
                    : `Video recordings will be deleted after ${retentionPeriodDays} days.`}
                </span>
              </div>
              <div></div>
            </div>
          </div>
        </div> */}
        {/* <div className="configuration-section">
          <h2>Email Reports</h2>
          <div className="policy-container">
            <div className="policy-dropdown">
              <div className="policy-description">
                Manage how frequently you receive reports on your email addresses.
              </div>

              <Box sx={{ display: 'flex', marginTop: 1, marginBottom: 1 }}>
                <TextField
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  placeholder="Enter email address"
                  size="small"
                  sx={inputStyle}
                />
                <Button onClick={handleAddEmail} variant="contained" sx={{ ...inputStyle, marginLeft: 1 }}>
                  Add
                </Button>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {emailAddresses.map((email) => (
                  <Chip key={email} label={email} onDelete={() => handleRemoveEmail(email)} sx={chipStyle} />
                ))}
              </Box>
              <br></br>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Select
                  value={reportFrequency}
                  onChange={handleFrequencyChange}
                  size="small"
                  sx={{ ...inputStyle, minWidth: 120 }}
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
                <Box sx={timeBoxStyle}>
                  <Select
                    value={reportHour}
                    onChange={handleHourChange}
                    size="small"
                    sx={{ ...timeSelectStyle, minWidth: 60 }}
                  >
                    {hours.map((hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    value={reportMinute}
                    onChange={handleMinuteChange}
                    size="small"
                    sx={{ ...timeSelectStyle, minWidth: 60 }}
                  >
                    {minutes.map((minute) => (
                      <MenuItem key={minute} value={minute}>
                        {minute.toString().padStart(2, '0')}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    value={reportAmPm}
                    onChange={handleAmPmChange}
                    size="small"
                    sx={{ ...timeSelectStyle, minWidth: 60 }}
                  >
                    <MenuItem value="AM">AM</MenuItem>
                    <MenuItem value="PM">PM</MenuItem>
                  </Select>
                </Box>
                <Select
                  value={timezone}
                  onChange={handleTimezoneChange}
                  size="small"
                  sx={{ ...inputStyle, minWidth: 200 }}
                >
                  {commonTimezones.map((tz) => (
                    <MenuItem key={tz} value={tz}>
                      {tz}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </div>
          </div>
        </div> */}

        {featureMode === 'dev' && (
          <div className="configuration-section">
            <h2>Advanced Settings</h2>
            <Link to="/device-management" className="device-management-link">
              Manage Devices
            </Link>
          </div>
        )}
      </div>

      <Modal open={consentModalOpen} onClose={handleConsentCancel}>
        <Box className="modal-container">
          <h2>Consent Required</h2>
          <p>By providing your phone number, you consent to receive text messages for notifications. Do you agree?</p>
          <div className="modal-buttons">
            <Button onClick={handleConsentCancel} className="cancel-button">
              Cancel
            </Button>
            <Button onClick={handleConsentAgree} className="confirm-button">
              Agree
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Configuration;

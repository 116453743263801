import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCircle } from '@fortawesome/free-solid-svg-icons';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import { format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';

import '../styles/DeviceAssignment.css';

const formatLastShiftTime = (lastShiftTime, shiftStatus, timeInShift, isOffline) => {
  if (shiftStatus) {
    const formattedTime = formatTimeInShift(timeInShift);
    console.log('Time in shift from DeviceAssignment: ', timeInShift);
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {formattedTime ? `Now (${formattedTime})` : 'Now'}
        {isOffline && (
          <Tooltip title="Device Offline" placement="top">
            <SignalWifiOffIcon style={{ marginLeft: '4px', color: 'gray', fontSize: '0.875rem' }} />
          </Tooltip>
        )}
      </div>
    );
  }
  if (!lastShiftTime) return '';
  const date = new Date(lastShiftTime);
  const now = new Date();
  if (date > now) return 'Future date';
  if (date.toDateString() === now.toDateString()) {
    return `Today at ${format(date, 'h:mm a')}`;
  }
  if (date.toDateString() === new Date(now.getTime() - 86400000).toDateString()) {
    return `Yesterday at ${format(date, 'h:mm a')}`;
  }
  if (date.getFullYear() === now.getFullYear()) {
    return format(date, 'M/d h:mm a');
  }
  return format(date, 'M/d/yyyy h:mm a');
};

const formatTimeInShift = (timeInShift) => {
  if (timeInShift === null) return '';
  if (timeInShift < 1) return 'Just started';
  if (timeInShift < 60) return `${timeInShift}m`;

  const hours = Math.floor(timeInShift / 60);
  const remainingMinutes = timeInShift % 60;
  return `${hours}h ${remainingMinutes}m`;
};

const formatRecordingTime = (time) => {
  if (time === null || time === undefined) return 'Recording now';
  if (time < 60) return `${Math.round(time)}s`;
  if (time < 3600) return `${Math.round(time / 60)}m`;
  return `${Math.round(time / 3600)}h`;
};

const DeviceAssignment = ({ handleDeviceClick, devices, deviceShifts, updateDeviceAssignment }) => {
  const [rows, setRows] = useState([]);
  const [editedRow, setEditedRow] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const devicesWithShiftData = useMemo(() => {
    return devices.map((device) => ({
      ...device,
      ...deviceShifts[device.deviceId],
    }));
  }, [devices, deviceShifts]);

  const sortedRows = useMemo(() => {
    return [...devicesWithShiftData].sort((a, b) => {
      if (a.shiftStatus && !b.shiftStatus) return -1;
      if (!a.shiftStatus && b.shiftStatus) return 1;
      if (a.shiftStatus && b.shiftStatus) {
        return (a.timeInShift || 0) - (b.timeInShift || 0);
      }
      const aTime = a.lastShiftTime ? new Date(a.lastShiftTime) : new Date(0);
      const bTime = b.lastShiftTime ? new Date(b.lastShiftTime) : new Date(0);
      return bTime - aTime;
    });
  }, [devicesWithShiftData]);

  useEffect(() => {
    const sortedRows = [...devicesWithShiftData].sort((a, b) => {
      if (a.shiftStatus && !b.shiftStatus) return -1;
      if (!a.shiftStatus && b.shiftStatus) return 1;
      if (a.shiftStatus && b.shiftStatus) {
        return (a.timeInShift || 0) - (b.timeInShift || 0);
      }
      const aTime = a.lastShiftTime ? new Date(a.lastShiftTime) : new Date(0);
      const bTime = b.lastShiftTime ? new Date(b.lastShiftTime) : new Date(0);
      return bTime - aTime;
    });

    setRows(sortedRows);
  }, [devicesWithShiftData]);

  const handleRowClick = (params) => {
    const deviceId = params.row.deviceId;
    if (deviceId) {
      handleDeviceClick(deviceId, [params.row.latitude, params.row.longitude]);
    } else {
      console.error('Device ID is missing in the row data');
    }
  };

  const columns = [
    {
      field: 'assignedTo',
      headerName: 'Owner',
      width: 150,
      editable: false,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <div style={{ fontSize: '0.875em' }}>{params.value || params.row.deviceName || 'Unassigned'}</div>
        </div>
      ),
    },
    {
      field: 'lastShiftTime',
      headerName: 'Last Shift',
      width: 240,
      renderCell: (params) => (
        <div style={{ fontSize: '0.875em', display: 'flex', alignItems: 'center' }}>
          {formatLastShiftTime(params.value, params.row.shiftStatus, params.row.timeInShift, params.row.isOffline)}
          {params.row.isRecording && (
            <Tooltip title={`Recording now`} placement="top">
              <FontAwesomeIcon icon={faCircle} className="recording-dot" />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  console.log('rows: ', rows);

  return (
    <div className={`device-assignment-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          getRowId={(row) => row.deviceId}
          onRowClick={handleRowClick}
          style={{ width: '100%', height: '100%' }}
          components={{
            Header: (props) => <div {...props} className="custom-header" />,
          }}
          componentsProps={{
            columnHeader: {
              style: {
                height: 30,
                fontSize: '0.875rem',
                lineHeight: '30px',
              },
            },
          }}
          hideFooter={rows.length <= 100}
          hideFooterPagination={rows.length <= 100}
        />
      </div>
    </div>
  );
};

export default DeviceAssignment;

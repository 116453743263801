import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import 'plyr-react/plyr.css';
import '../styles/VideoManagement.css';
import { cosmosClient } from '../utils/eventstorage';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';
import {
  Select,
  MenuItem,
  Box,
  FormControl,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material';
import { stringToColor } from '../utils/utilsUI';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faCalendar,
  faTimes,
  faExclamationTriangle,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import VideoPlayer from './VideoPlayer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Archive, Unarchive } from '@mui/icons-material';
import InboxIcon from '@mui/icons-material/Inbox';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { fetchOrgCreationTimestamp } from '../utils/cosmosQueries';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import { fetchVideosForDevices } from '../utils/cosmosQueries';

const databaseId = 'Videos';
const videosContainerId = 'Videos';
const archiveContainerId = 'ArchivedVideos';

const container = cosmosClient.database(databaseId).container(videosContainerId);
const archiveContainer = cosmosClient.database(databaseId).container(archiveContainerId);

const calculateDaysRemaining = (uploadDate, retentionPeriodDays) => {
  const currentDate = new Date();
  const uploadDateTime = new Date(uploadDate);
  const timeDiff = uploadDateTime.getTime() - currentDate.getTime() + retentionPeriodDays * 24 * 60 * 60 * 1000;
  const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return daysRemaining > 0 ? daysRemaining : 0;
};

const formatDuration = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const hours = Math.floor(mins / 60);
  const remainingMins = mins % 60;
  const remainingSecs = seconds % 60;
  return `${hours > 0 ? `${hours}h ` : ''}${remainingMins}m ${remainingSecs}s`;
};

const areEqual = (prevProps, nextProps) => {
  if (!prevProps.selectedVideo || !nextProps.selectedVideo) {
    return prevProps.selectedVideo === nextProps.selectedVideo;
  }

  return (
    prevProps.selectedVideo.BlobSAS === nextProps.selectedVideo.BlobSAS &&
    prevProps.selectedVideo.BlobPath === nextProps.selectedVideo.BlobPath &&
    JSON.stringify(prevProps.selectedVideo.Tags) === JSON.stringify(nextProps.selectedVideo.Tags) &&
    prevProps.onVideoArchived === nextProps.onVideoArchived &&
    prevProps.isArchived === nextProps.isArchived
  );
};

export const addTagStyles = (tagText) => {
  const className = `tag-style-${tagText.replace(/\s+/g, '-')}`;
  const color = stringToColor(tagText);

  if (!document.head.querySelector(`.${className}`)) {
    const style = document.createElement('style');
    style.innerHTML = `
      .${className} {
        background-color: ${color} !important;
        color: #000 !important;
      }
    `;
    document.head.appendChild(style);
  }

  return className;
};

const getTagProps = (tag, index) => ({
  key: index,
  className: addTagStyles(tag.text),
});

const MarkerControlPanel = ({ markers, currentTime, onJumpToMarker, preStart = 10 }) => {
  const [currentMarkerIndex, setCurrentMarkerIndex] = useState(0);

  useEffect(() => {
    const newIndex = markers.findIndex((marker) => marker.time > currentTime);
    setCurrentMarkerIndex(newIndex === -1 ? markers.length - 1 : Math.max(0, newIndex - 1));
  }, [currentTime, markers]);

  const handlePrevious = () => {
    if (currentMarkerIndex > 0) {
      const newIndex = currentMarkerIndex - 1;
      onJumpToMarker(Math.max(0, markers[newIndex].time - preStart));
    }
  };

  const handleNext = () => {
    if (currentMarkerIndex < markers.length - 1) {
      const newIndex = currentMarkerIndex + 1;
      onJumpToMarker(Math.max(0, markers[newIndex].time - preStart));
    }
  };

  return (
    <div className="marker-control-panel">
      <button onClick={handlePrevious} disabled={currentMarkerIndex <= 0 || markers.length <= 1}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <button onClick={handleNext} disabled={currentMarkerIndex >= markers.length - 1 || markers.length <= 1}>
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  );
};

const VideoDisplay = React.memo(
  ({
    selectedVideo,
    handleDeleteTag,
    handleAddTag,
    onDeleteVideo,
    onVideoArchived,
    onVideoUnarchived,
    searchResults,
    initialTimestamp,
    urlFileName,
    videos,
    isArchived,
    retentionPeriodDays,
  }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const playerRef = useRef(null);
    const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
    const [showUnarchiveConfirmation, setShowUnarchiveConfirmation] = useState(false);
    const [showUnarchiveSuccess, setShowUnarchiveSuccess] = useState(false);

    const toggleDeleteModal = useCallback(() => {
      setShowDeleteModal((prev) => !prev);
    }, []);

    useEffect(() => {
      if (selectedVideo) {
        const timer = setTimeout(() => setShowVideo(true), 0);
        return () => clearTimeout(timer);
      } else {
        setShowVideo(false);
      }
    }, [selectedVideo]);

    const handleDelete = async () => {
      try {
        onDeleteVideo(selectedVideo.id);
        await container.item(selectedVideo.id, selectedVideo.AccountId).delete();
        toggleDeleteModal();
      } catch (error) {
        console.error('Error deleting video from Cosmos DB:', error);
        console.log(
          'Failed to delete the video. Please try again. selectedVideo.id: ',
          selectedVideo.id,
          ' selectedVideo.deviceId: ',
          selectedVideo.deviceId,
          ' selectedVideo.AccountId: ',
          selectedVideo.AccountId
        );
      }
    };

    const videoToDisplay = selectedVideo || (urlFileName ? videos.find((v) => v.FileName === urlFileName) : null);
    console.log('videoToDisplay: ', videoToDisplay);

    if (!selectedVideo) {
      return (
        <div className="default-message">
          <i className="fas fa-hand-pointer fa-5x"></i>
          <p>Select a video on the left to view details</p>
        </div>
      );
    }

    const markers = [
      ...(videoToDisplay.FileName === urlFileName && initialTimestamp
        ? [
            {
              time: initialTimestamp,
              label: 'Escalation',
            },
          ]
        : []),
      ...searchResults
        .filter((result) => result.fileName === videoToDisplay.FileName)
        .flatMap((result) => {
          const timestamps = Array.isArray(result.timestamp) ? result.timestamp : [result.timestamp];
          return timestamps.map((time) => {
            const truncatedQuery = result.query.length > 10 ? `${result.query.slice(0, 10)}...` : result.query;
            return {
              time,
              label: `"${truncatedQuery}"`,
            };
          });
        }),
    ];

    const handleJumpToMarker = (time) => {
      if (playerRef.current && playerRef.current.plyr) {
        playerRef.current.plyr.currentTime = time;
      }
    };

    const handleTimeUpdate = (event) => {
      setCurrentTime(event.target.currentTime);
    };

    const handleArchiveConfirmation = () => {
      setShowArchiveConfirmation(true);
    };

    const handleUnarchiveConfirmation = () => {
      setShowUnarchiveConfirmation(true);
    };

    const handleUnarchiveCancel = () => {
      setShowUnarchiveConfirmation(false);
    };

    const handleUnarchiveConfirm = async () => {
      setShowUnarchiveConfirmation(false);
      try {
        await unarchiveVideo(selectedVideo);
        onVideoUnarchived(selectedVideo.id);
      } catch (error) {
        console.error('Error unarchiving video:', error);
      }
    };

    const unarchiveVideo = async (video) => {
      console.log('Unarchiving video:', video);
      try {
        await archiveContainer.item(video.id, video.AccountId).delete();
        await container.item(video.id, video.AccountId).patch([
          {
            op: 'add',
            path: '/isArchived',
            value: false,
          },
        ]);
        console.log('Video unarchived successfully');

        axios
          .post('https://vectordb.plix.ai/v1/movesemistructured', {
            filename: video.FileName,
            source: 'archive',
            destination: 'all',
          })
          .then(() => {
            console.log('Semi-structured data moved successfully');
          })
          .catch((error) => {
            console.error('Error moving semi-structured data:', error);
          });
      } catch (error) {
        console.error('Error unarchiving video:', error);
      }
    };

    const handleArchiveCancel = () => {
      setShowArchiveConfirmation(false);
    };

    const handleArchiveConfirm = async () => {
      setShowArchiveConfirmation(false);
      try {
        await archiveVideo(selectedVideo);
        onVideoArchived(selectedVideo.id);
      } catch (error) {
        console.error('Error archiving video:', error);
      }
    };

    const archiveVideo = async (video) => {
      console.log('Archiving video:', video);
      try {
        const archivedVideo = {
          ...video,
          archivedOnTimestamp: new Date().toISOString(),
        };
        await archiveContainer.items.create(archivedVideo);
        console.log('Video archived successfully');

        await container.item(video.id, video.AccountId).patch([
          {
            op: 'add',
            path: '/isArchived',
            value: true,
          },
        ]);

        axios
          .post('https://vectordb.plix.ai/v1/movesemistructured', {
            filename: video.FileName,
            source: 'all',
            destination: 'archive',
          })
          .then(() => {
            console.log('Semi-structured data moved successfully');
          })
          .catch((error) => {
            console.error('Error moving semi-structured data:', error);
          });
      } catch (error) {
        if (error.code === 409) {
          const archivedVideo = {
            ...video,
            archivedOnTimestamp: new Date().toISOString(),
          };
          await archiveContainer.item(video.id, video.AccountId).replace(archivedVideo);
          console.log('Existing archived video updated');
        } else {
          console.error('Error archiving video:', error);
        }
      }
    };

    const getRetentionWarning = () => {
      const uploadDate = new Date(selectedVideo.TimeUploaded);
      const currentDate = new Date();
      const daysElapsed = Math.floor((currentDate - uploadDate) / (1000 * 60 * 60 * 24));
      const daysUntilDeletion = retentionPeriodDays - daysElapsed;

      if (daysUntilDeletion <= 0) {
        return {
          severity: 'error',
          message:
            'Warning: This video is past its retention period of ' +
            retentionPeriodDays +
            ' days and will be deleted immediately upon unarchiving. This action cannot be undone.',
        };
      } else if (daysUntilDeletion <= 7) {
        return {
          severity: 'warning',
          message:
            'Warning: This video is scheduled for deletion in ' +
            (daysUntilDeletion === 1 ? ' less than 24 hours' : daysUntilDeletion + ' days') +
            ' based on the retention policy.',
        };
      } else {
        return {
          severity: 'info',
          message:
            'This video is scheduled for deletion in ' + daysUntilDeletion + ' days based on the retention policy.',
        };
      }
      return null;
    };

    const retentionWarning = getRetentionWarning();

    return (
      <>
        {showVideo && (
          <div className="video-player-wrapper">
            <div className="video-player-container">
              <VideoPlayer
                ref={playerRef}
                videoFileName={videoToDisplay.FileName}
                markers={markers}
                token={videoToDisplay.BlobSAS}
                preStart={2}
                onTimeUpdate={handleTimeUpdate}
                transcriptUrl={selectedVideo.transcriptUrl}
                initialTime={initialTimestamp}
              />
            </div>
          </div>
        )}
        <div className="video-metadata">
          <div className="metadata-header">
            <h2 className="filename">
              {typeof videoToDisplay.FileName === 'string' ? videoToDisplay.FileName : 'Untitled'}
            </h2>
            <div className="metadata-actions">
              {!isArchived ? (
                <Tooltip
                  title="Archive"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#333',
                        color: '#fff',
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: '12px',
                        borderRadius: '4px',
                        padding: '8px 12px',
                      },
                    },
                    arrow: {
                      sx: {
                        color: '#333',
                      },
                    },
                  }}
                >
                  <div onClick={handleArchiveConfirmation} className="archive-button">
                    <Archive />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Unarchive"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#333',
                        color: '#fff',
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: '12px',
                        borderRadius: '4px',
                        padding: '8px 12px',
                      },
                    },
                    arrow: {
                      sx: {
                        color: '#333',
                      },
                    },
                  }}
                >
                  <div onClick={handleUnarchiveConfirmation} className="archive-button">
                    <Unarchive />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="metadata-content">
            <div className="metadata-details">
              <div className="metadata-item">
                <span className="metadata-label">Owner:</span>
                <span className="metadata-value">{videoToDisplay.AssignedTo ? videoToDisplay.AssignedTo : '--'}</span>
              </div>
              <div className="metadata-item">
                <span className="metadata-label">Date:</span>
                <span className="metadata-value">{videoToDisplay.date}</span>
              </div>
              <div className="metadata-item">
                <span className="metadata-label">Time:</span>
                <span className="metadata-value">{videoToDisplay.time}</span>
              </div>
              <div className="metadata-item">
                <span className="metadata-label">Duration:</span>
                <span className="metadata-value">{videoToDisplay.formattedDuration}</span>
              </div>
              {!isArchived ? (
                <div className="metadata-item">
                  <span className="metadata-label">Scheduled for deletion on:</span>
                  <span className="metadata-value">
                    {new Date(
                      new Date(videoToDisplay.TimeUploaded).getTime() + retentionPeriodDays * 24 * 60 * 60 * 1000
                    ).toLocaleString()}
                    {videoToDisplay.age >= retentionPeriodDays - 7 && (
                      <Tooltip
                        title={`This video is scheduled for deletion in ${retentionPeriodDays - videoToDisplay.age > 1 ? `${retentionPeriodDays - videoToDisplay.age} days!` : 'less than 1 day!'}`}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: '#333',
                              color: '#fff',
                              fontFamily: "'Montserrat', sans-serif",
                              fontSize: '12px',
                              borderRadius: '4px',
                              padding: '8px 12px',
                              width: 'auto',
                              maxWidth: 'none',
                              whiteSpace: 'nowrap',
                            },
                          },
                          arrow: {
                            sx: {
                              color: '#333',
                            },
                          },
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          style={{
                            color: '#eed202',
                            marginLeft: '5px',
                            padding: '2px',
                          }}
                        />
                      </Tooltip>
                    )}
                  </span>
                </div>
              ) : (
                <div className="metadata-item">
                  <span className="metadata-label">Archived on:</span>
                  <span className="metadata-value">
                    {videoToDisplay.archivedOnTimestamp
                      ? new Date(videoToDisplay.archivedOnTimestamp).toLocaleString()
                      : 'Unavailable'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          open={showDeleteModal}
          onClose={toggleDeleteModal}
          aria-labelledby="delete-video-modal-title"
          aria-describedby="delete-video-modal-description"
        >
          <div className="modal-container">
            <h2 id="delete-video-modal-title">Confirm Delete</h2>
            <p id="delete-video-modal-description">
              Deleting this video is a permanent action that cannot be undone. Are you sure you want to delete this
              video?
            </p>
            <div className="modal-buttons">
              <button onClick={toggleDeleteModal} className="cancel-delete-button">
                Cancel
              </button>
              <button onClick={handleDelete} className="confirm-delete-button">
                Yes, Delete
              </button>
            </div>
          </div>
        </Modal>

        <Dialog
          open={showArchiveConfirmation}
          onClose={handleArchiveCancel}
          aria-labelledby="archive-dialog-title"
          aria-describedby="archive-dialog-description"
        >
          <DialogTitle id="archive-dialog-title">Archive video</DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-dialog-description">
              Are you sure you want to archive this video?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="dialog-actions">
              <Button onClick={handleArchiveCancel}>Cancel</Button>
              <Button onClick={handleArchiveConfirm} autoFocus>
                Archive
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showUnarchiveConfirmation}
          onClose={handleUnarchiveCancel}
          aria-labelledby="unarchive-dialog-title"
          aria-describedby="unarchive-dialog-description"
        >
          <DialogTitle id="unarchive-dialog-title">Unarchive video</DialogTitle>
          <DialogContent>
            {retentionWarning && (
              <MuiAlert severity={retentionWarning.severity} sx={{ mb: 2 }}>
                {retentionWarning.message}
              </MuiAlert>
            )}
            {/* <DialogContentText id="unarchive-dialog-description">
              Are you sure you want to unarchive this video? This will move the video back to the main video list and it will be deleted after the retention period.
            </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <div className="dialog-actions">
              <Button onClick={handleUnarchiveCancel}>Cancel</Button>
              <Button onClick={handleUnarchiveConfirm} autoFocus>
                Unarchive
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    );
  },
  areEqual
);

const VideoManagement = (props) => {
  const [viewArchived, setViewArchived] = useState(false);
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [user] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [searchType, setSearchType] = useState('video');
  const [showModal, setShowModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [shouldFadeIn, setShouldFadeIn] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialTimestamp, setInitialTimestamp] = useState(0);
  const [urlFileName, setUrlFileName] = useState(null);
  const location = useLocation();
  const [dateRange, setDateRange] = useState([null, null]);
  const [ownerFilter, setOwnerFilter] = useState('');
  const [archivedVideos, setArchivedVideos] = useState([]);
  const [showArchiveSuccess, setShowArchiveSuccess] = useState(false);
  const [filteredArchivedVideos, setFilteredArchivedVideos] = useState([]);
  const [showUnarchiveSuccess, setShowUnarchiveSuccess] = useState(false);

  const { devices, selectedOrg } = useDeviceFilter();

  const searchVideos = async (query, userId, isAudio, videos = [], searchTarget = 'all') => {
    if (!query) {
      return videos.map((video) => ({ fileName: video.FileName, timestamp: 0, query: '' }));
    }

    console.log('query: ', query);
    const lowerCaseQuery = query.toLowerCase();

    let url = !isAudio ? 'https://vectordb.plix.ai/v1/videosearch' : 'https://vectordb.plix.ai/v1/transcriptsearch';

    const data = {
      query: lowerCaseQuery,
      userId: props.userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId,
      searchTarget: searchTarget,
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const videoMap = new Map(videos.map((video) => [video.FileName, video]));

      const filteredVideos = response.data
        .filter((result) => videoMap.has(result.filename))
        .map((result) => {
          const video = videoMap.get(result.filename);
          return {
            fileName: video.FileName,
            timestamp: Array.isArray(result.timestamp) ? result.timestamp : [result.timestamp],
            query,
          };
        });

      return filteredVideos;
    } catch (error) {
      console.error('Error:', error);
      return videos;
    }
  };

  useEffect(() => {
    if (videos.length === 0) {
      setShouldFadeIn(true);
    } else {
      setShouldFadeIn(false);
    }
  }, [videos.length]);

  useEffect(() => {
    if (devices.length > 0 && props.retentionPeriodDays) {
      fetchVideos();
    }
  }, [devices.length, props.retentionPeriodDays, selectedOrg]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filename = searchParams.get('filename');
    const timestamp = searchParams.get('timestamp');

    if (filename && timestamp) {
      setInitialTimestamp(parseFloat(timestamp));
      setUrlFileName(filename);
    } else {
      setInitialTimestamp(0);
      setUrlFileName(null);
      setSelectedVideo(null);
    }
  }, [location]);

  useEffect(() => {
    if (videos.length > 0 && urlFileName) {
      const video = videos.find((v) => v.FileName === urlFileName);
      if (video) {
        selectVideo(video);
      }
    }
  }, [videos, urlFileName]);

  const fetchVideos = async () => {
    setIsLoading(true);
    try {
      const rawVideosList = await fetchVideosForDevices(props.userId, devices, props.retentionPeriodDays, selectedOrg);
      console.log('rawVideosList: ', rawVideosList);
      const formattedVideosList = rawVideosList
        .map((item) => {
          const timeUploaded = new Date(item.TimeRecorded || item.TimeUploaded);
          const time = timeUploaded.toLocaleTimeString();
          const date = timeUploaded.toLocaleDateString();
          const durationMilliseconds = parseInt(item.Duration);
          const durationSeconds = isNaN(durationMilliseconds) ? 0 : Math.floor(durationMilliseconds / 1000);
          const hours = Math.floor(durationSeconds / 3600);
          const minutes = Math.floor((durationSeconds % 3600) / 60);
          const seconds = durationSeconds % 60;
          const formattedDuration = `${hours > 0 ? `${hours}h ` : ''}${minutes}m ${seconds}s`;

          const currentDate = new Date();
          const age = Math.floor((currentDate - timeUploaded) / (1000 * 60 * 60 * 24));

          return {
            ...item,
            time,
            date,
            formattedDuration,
            timeUploaded: timeUploaded.getTime(),
            age: age,
            timeToDeletion: calculateDaysRemaining(timeUploaded, props.retentionPeriodDays),
            AssignedTo: devices.find((d) => d.deviceId === item.deviceId)?.assignedTo,
            transcriptUrl: item.TranscriptVTTPath,
          };
        })
        .filter((item) => parseInt(item.Duration) > 1000 * 10);

      setVideos(formattedVideosList);
      setFilteredVideos(formattedVideosList);
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTag = useCallback(
    (i) => {
      const newTags = selectedVideo.Tags ? [...selectedVideo.Tags] : [];
      newTags.splice(i, 1);
      const updatedVideo = { ...selectedVideo, Tags: newTags };
      setVideos((prevVideos) => prevVideos.map((video) => (video.id === updatedVideo.id ? updatedVideo : video)));
      setFilteredVideos((prevFilteredVideos) =>
        prevFilteredVideos.map((video) => (video.id === updatedVideo.id ? updatedVideo : video))
      );
      setSelectedVideo(updatedVideo);
      updateVideoMetadata(updatedVideo).catch((error) => {
        console.error('Error updating video metadata in Cosmos DB:', error);
      });
    },
    [selectedVideo]
  );

  const handleAddTag = useCallback(
    (tag) => {
      const newTags = selectedVideo.Tags ? [...selectedVideo.Tags, tag] : [tag];
      const updatedVideo = { ...selectedVideo, Tags: newTags };
      setVideos((prevVideos) => prevVideos.map((video) => (video.id === updatedVideo.id ? updatedVideo : video)));
      setFilteredVideos((prevFilteredVideos) =>
        prevFilteredVideos.map((video) => (video.id === updatedVideo.id ? updatedVideo : video))
      );
      setSelectedVideo(updatedVideo);
      updateVideoMetadata(updatedVideo).catch((error) => {
        console.error('Error updating video metadata in Cosmos DB:', error);
      });
    },
    [selectedVideo]
  );

  const updateVideoMetadata = async (video) => {
    try {
      const { id, AccountId, Tags } = video;
      const { resource: item } = await container.item(id, AccountId).read();
      if (item) {
        item.Tags = Tags;
        const { resource: updatedItem } = await container.item(id, AccountId).replace(item);
        console.log('Updated item:', updatedItem.id);
      }
    } catch (error) {
      console.error('Error updating video metadata in Cosmos DB:', error);
    }
  };

  const selectVideo = (video) => {
    const isUrlVideo = video.FileName === urlFileName;
    setSelectedVideo({
      ...video,
      initialTimestamp: isUrlVideo ? initialTimestamp : 0,
      urlFileName: isUrlVideo ? urlFileName : null,
    });
    if (!isUrlVideo) {
      setInitialTimestamp(0);
      setUrlFileName(null);
    }
  };
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onDeleteVideo = (deletedVideoId) => {
    const remainingVideos = videos.filter((video) => video.id !== deletedVideoId);
    setSelectedVideo(null);
    setVideos(remainingVideos);
    fetchVideos();
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDateChange = (update) => {
    setDateRange(update);
    if (update[0] && update[1]) {
      const newEndDate = new Date(update[1]);
      newEndDate.setDate(newEndDate.getDate() + 1);
      handleSearch(searchQuery, update[0], newEndDate);
    } else {
      handleSearch(searchQuery, null, null);
    }
  };

  const handleSearch = async (query, startDate, endDate, owner = ownerFilter) => {
    const isAudio = searchType === 'audio';
    setIsLoading(true);
    setSelectedVideo(null);

    try {
      const results = await searchVideos(
        query,
        props.userId,
        isAudio,
        viewArchived ? archivedVideos : videos,
        viewArchived ? 'archive' : 'all'
      );
      setSearchResults(results);
      const resultFileNames = results.map((result) => result.fileName);

      const videoMap = new Map((viewArchived ? archivedVideos : videos).map((video) => [video.FileName, video]));
      let filteredVideos = resultFileNames
        .map((fileName) => videoMap.get(fileName))
        .filter((video) => video !== undefined);

      if (startDate && endDate) {
        filteredVideos = filteredVideos.filter((video) => {
          const videoDate = new Date(video.date);
          return videoDate >= startDate && videoDate <= endDate;
        });
      }

      if (owner) {
        filteredVideos = filteredVideos.filter((video) => video.AssignedTo === owner);
      }

      if (viewArchived) {
        setFilteredArchivedVideos(filteredVideos);
      } else {
        setFilteredVideos(filteredVideos);
      }
    } catch (error) {
      console.error('Error during search:', error);
      if (viewArchived) {
        setFilteredArchivedVideos([]);
      } else {
        setFilteredVideos([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resetFilters = () => {
    setSearchQuery('');
    setDateRange([null, null]);
    setOwnerFilter('');
    if (viewArchived) {
      setFilteredArchivedVideos(archivedVideos);
    } else {
      setFilteredVideos(videos);
    }
    setSearchResults([]);
    setSelectedVideo(null);
  };

  const uniqueOwners = [...new Set(videos.map((video) => video.AssignedTo))].filter(Boolean);

  const CustomInput = React.forwardRef(({ value, onClick, onClear }, ref) => (
    <div className="date-picker-wrapper" onClick={onClick}>
      <button className="calendar-button" ref={ref}>
        <FontAwesomeIcon icon={faCalendar} />
      </button>
      {value && (
        <>
          <span className="selected-date-range">{value}</span>
          <button className="clear-date-button" onClick={onClear}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </>
      )}
    </div>
  ));

  const handleClearDate = () => {
    setDateRange([null, null]);
    handleSearch(searchQuery, null, null);
  };

  const handleOwnerFilterChange = (event) => {
    const newOwnerFilter = event.target.value;
    setOwnerFilter(newOwnerFilter);
    handleSearch(searchQuery, dateRange[0], dateRange[1], newOwnerFilter);
  };

  const handleVideoArchived = useCallback((archivedVideoId) => {
    setVideos((prevVideos) => prevVideos.filter((video) => video.id !== archivedVideoId));
    setFilteredVideos((prevFilteredVideos) => prevFilteredVideos.filter((video) => video.id !== archivedVideoId));
    setSelectedVideo(null);
    setShowArchiveSuccess(true);
  }, []);

  const handleCloseArchiveSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowArchiveSuccess(false);
  };

  const fetchArchivedVideos = async () => {
    setIsLoading(true);
    try {
      const deviceIds = devices.map((device) => device.deviceId);
      const deviceIdsString = deviceIds.map((id) => `'${id}'`).join(', ');

      const query = `
        SELECT * from c 
        WHERE c.deviceId IN (${deviceIdsString}) 
        ${props.userId === PLIX_OVERWATCH_ACCOUNT ? `AND c.userId = @userId` : ''}
        ORDER BY c.TimeUploaded DESC
      `;

      const querySpec = {
        query: query,
        parameters: props.userId === PLIX_OVERWATCH_ACCOUNT ? [{ name: '@userId', value: selectedOrg }] : [],
      };

      const { resources: items } = await archiveContainer.items.query(querySpec).fetchAll();

      const currentDate = new Date();
      const formattedItems = items.map((item) => {
        const timeUploaded = new Date(item.TimeRecorded || item.TimeUploaded);
        const time = timeUploaded.toLocaleTimeString();
        const date = timeUploaded.toLocaleDateString();
        const durationMilliseconds = parseInt(item.Duration);
        const durationSeconds = isNaN(durationMilliseconds) ? 0 : Math.floor(durationMilliseconds / 1000);
        const hours = Math.floor(durationSeconds / 3600);
        const minutes = Math.floor((durationSeconds % 3600) / 60);
        const seconds = durationSeconds % 60;
        const formattedDuration = `${hours > 0 ? `${hours}h ` : ''}${minutes}m ${seconds}s`;

        const age = Math.floor((currentDate - timeUploaded) / (1000 * 60 * 60 * 24));

        return {
          ...item,
          time,
          date,
          formattedDuration,
          timeUploaded: timeUploaded.getTime(),
          age: age,
          timeToDeletion: calculateDaysRemaining(timeUploaded, props.retentionPeriodDays),
        };
      });

      setArchivedVideos(formattedItems);
      setFilteredArchivedVideos(formattedItems);
    } catch (error) {
      console.error('Error fetching archived videos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleArchive = useCallback(() => {
    setViewArchived((prevState) => {
      const newViewArchived = !prevState;
      // Use the new value of viewArchived to determine which actions to take
      if (newViewArchived) {
        // Switching to archived view
        setFilteredVideos([]);
        fetchArchivedVideos();
      } else {
        // Switching to regular view
        setArchivedVideos([]);
        setFilteredArchivedVideos([]);
        fetchVideos();
      }

      // Reset common states
      setSelectedVideo(null);
      setSearchQuery('');
      setDateRange([null, null]);
      setOwnerFilter('');
      setSearchResults([]);

      return newViewArchived;
    });
  }, [fetchArchivedVideos, fetchVideos]);

  const handleVideoUnarchived = useCallback((unarchivedVideoId) => {
    setArchivedVideos((prevArchivedVideos) => prevArchivedVideos.filter((video) => video.id !== unarchivedVideoId));
    setFilteredArchivedVideos((prevFilteredArchivedVideos) =>
      prevFilteredArchivedVideos.filter((video) => video.id !== unarchivedVideoId)
    );
    setSelectedVideo(null);
    setShowUnarchiveSuccess(true);
    fetchVideos(); // Refresh the main video list
  }, []);

  const handleCloseUnarchiveSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowUnarchiveSuccess(false);
  };

  const columns = [
    { field: 'FileName', headerName: 'Video Title', flex: 2.5 },
    {
      field: 'AssignedTo',
      headerName: 'Owner',
      flex: 1,
      hideable: false,
      renderCell: (params) => {
        return params.value ? params.value : '--';
      },
    },
    { field: 'date', headerName: 'Date', flex: 1, hide: true, hideable: false },
    { field: 'time', headerName: 'Time', flex: 1, hide: true, hideable: false },
    { field: 'formattedDuration', headerName: 'Duration', flex: 1 },
    {
      field: 'timeToDeletion',
      headerName: 'Time to Deletion',
      flex: 1,
      renderCell: (params) => {
        if (props.retentionPeriodDays === 100000) {
          return <span style={{ fontSize: '1.2em' }}>∞</span>;
        }
        const daysRemaining = calculateDaysRemaining(params.row.TimeRecorded, props.retentionPeriodDays);
        const text = daysRemaining <= 1 ? '< 1 day' : `${daysRemaining} days`;
        return <span style={{ color: daysRemaining <= 1 ? '#cb2d3e' : 'inherit' }}>{text}</span>;
      },
    },
  ];

  const archivedColumns = [
    ...columns.filter((column) => column.field !== 'timeToDeletion'),
    {
      field: 'download',
      headerName: 'Download',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleDownload(params.row);
            }}
            className="download-button"
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 16px' }}>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
        </GridToolbarContainer>
        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeIcon style={{ marginRight: '4px', fontSize: '1rem' }} />
          {props.retentionPeriodDays >= 100000
            ? 'Video recordings retained indefinitely'
            : `Video recordings retained for ${props.retentionPeriodDays} days`}
        </Typography>
      </div>
    );
  };

  const [loggingContainer, setLoggingContainer] = useState(null);

  // Initialize logging container
  useEffect(() => {
    const initializeLoggingContainer = async () => {
      try {
        const container = cosmosClient.database('Videos').container('VideoEvents');
        setLoggingContainer(container);
      } catch (error) {
        console.error('Error initializing logging container:', error);
      }
    };

    initializeLoggingContainer();
  }, []);

  const logUserInteraction = async (action, metadata) => {
    if (!loggingContainer) return;

    const logEntry = {
      id: new Date().toISOString(),
      userId: props.userId,
      action,
      timestamp: new Date().toISOString(),
      ...metadata,
    };

    try {
      await loggingContainer.items.create(logEntry);
      console.log('User interaction logged successfully');
    } catch (error) {
      console.error('Error logging user interaction:', error);
    }
  };

  const handleDownload = async (video) => {
    const downloadUrl = `${video.BlobPath}?${video.BlobSAS}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = video.FileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Log the download interaction
    await logUserInteraction('download', {
      videoId: video.id,
      fileName: video.FileName,
      deviceId: video.deviceId,
      accountId: video.AccountId,
      blobPath: video.BlobPath,
      blobSAS: video.BlobSAS,
      duration: video.Duration,
      timeUploaded: video.TimeUploaded,
      timeRecorded: video.TimeRecorded,
      userId: video.userId,
      transcriptVTTPath: video.transcriptUrl,
      assignedTo: video.AssignedTo,
    });
  };

  return (
    <div className="app-container">
      <div className="controls-container">
        <div className="search-bar-container-video">
          <div className="search-input-wrapper">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch(searchQuery, dateRange[0], dateRange[1]);
                }
              }}
              placeholder={`Search`}
              className="search-input search-input-rounded-left"
            />
            <ToggleButtonGroup
              value={searchType}
              exclusive
              onChange={(e, newValue) => setSearchType(newValue)}
              aria-label="search type"
              size="small"
              className="search-type-toggle"
            >
              <ToggleButton value="video" aria-label="video search">
                <strong>Video</strong>
              </ToggleButton>
              <ToggleButton value="audio" aria-label="audio search">
                <strong>Audio</strong>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <DatePicker
            selected={dateRange[0]}
            onChange={handleDateChange}
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            selectsRange
            customInput={<CustomInput onClear={handleClearDate} />}
            className="date-range-filter-videos"
          />
          <FormControl sx={{ m: 1, minWidth: 120 }} variant="outlined">
            <Select value={ownerFilter} onChange={handleOwnerFilterChange} displayEmpty className="owner-filter">
              <MenuItem value="">All Users</MenuItem>
              {uniqueOwners.map((owner) => (
                <MenuItem key={owner} value={owner}>
                  {owner}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() => handleSearch(searchQuery, dateRange[0], dateRange[1])}
            className="filter-button"
          >
            Search
          </Button>
          <Button variant="outlined" onClick={resetFilters} className="reset-button">
            Reset
          </Button>
        </div>
        <div className="archived-inventory-toggle" onClick={handleArchive}>
          {/* <div className="video-management-tooltips"> */}
          <Tooltip
            title={viewArchived ? 'All Videos' : 'Archived'}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: '#333',
                  color: '#fff',
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: '12px',
                  borderRadius: '4px',
                  padding: '8px 12px',
                },
              },
              arrow: {
                sx: {
                  color: '#333',
                },
              },
            }}
          >
            {viewArchived ? <InboxIcon /> : <InventoryIcon />}
          </Tooltip>
          {/* </div> */}
        </div>
      </div>
      {isLoading && (
        <div className="loading-spinner">
          <i className="fas fa-spinner spinner"></i>
        </div>
      )}
      <div className="video-management-content-container">
        {viewArchived ? (
          <Box
            className="archived-videos-container"
            sx={{
              height: '100%',
              width: '50%',
              minWidth: 500,
              overflow: 'auto',
              resize: 'horizontal',
            }}
          >
            {isLoading ? (
              <div className="loading-spinner">
                <i className="fas fa-spinner spinner"></i>
              </div>
            ) : filteredArchivedVideos.length > 0 ? (
              <DataGrid
                rows={filteredArchivedVideos}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      FileName: false,
                      date: true,
                      time: true,
                    },
                  },
                }}
                columns={archivedColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                onRowClick={(params) => selectVideo(params.row)}
                slots={{
                  toolbar: CustomToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: false,
                    quickFilterProps: { debounceMs: 500 },
                    filterToolbarProps: {
                      showFilterPanel: false,
                      showColumnsSelector: false,
                    },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                  },
                }}
                sx={{
                  height: '100%',
                  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                    width: '10%',
                    cursor: 'pointer',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  },
                }}
              />
            ) : (
              <div
                className={shouldFadeIn ? 'fade-in' : ''}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '50%',
                  margin: '5% auto',
                }}
              >
                <div>{isLoading ? 'Searching...' : 'No videos found'}</div>
              </div>
            )}
          </Box>
        ) : (
          <Box
            className="resizable-container"
            sx={{
              height: '100%',
              width: '50%',
              minWidth: 500,
              overflow: 'auto',
              resize: 'horizontal',
            }}
          >
            {filteredVideos.length > 0 ? (
              <DataGrid
                rows={filteredVideos}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      date: true,
                      time: true,
                      FileName: false,
                      timeToDeletion: true,
                    },
                  },
                }}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                onRowClick={(params) => selectVideo(params.row)}
                slots={{
                  toolbar: CustomToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: false,
                    quickFilterProps: { debounceMs: 500 },
                    filterToolbarProps: {
                      showFilterPanel: false,
                      showColumnsSelector: false,
                    },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                  },
                }}
                sx={{
                  height: '100%',
                  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                    width: '10%',
                    cursor: 'pointer',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  },
                }}
              />
            ) : (
              <div
                className={shouldFadeIn ? 'fade-in' : ''}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '50%',
                  margin: '5% auto',
                }}
              >
                <div>{isLoading ? 'Searching...' : 'No videos found'}</div>
              </div>
            )}
          </Box>
        )}

        <div className="video-display">
          {selectedVideo ? (
            <VideoDisplay
              retentionPeriodDays={props.retentionPeriodDays}
              selectedVideo={selectedVideo}
              handleDeleteTag={handleDeleteTag}
              handleAddTag={handleAddTag}
              onDeleteVideo={onDeleteVideo}
              onVideoArchived={handleVideoArchived}
              onVideoUnarchived={handleVideoUnarchived}
              searchResults={searchResults}
              initialTimestamp={initialTimestamp}
              urlFileName={urlFileName}
              videos={videos}
              transcriptUrl={selectedVideo.transcriptUrl}
              isArchived={viewArchived}
              handleDownload={handleDownload}
            />
          ) : filteredVideos.length > 0 || filteredArchivedVideos.length > 0 ? (
            <div className="default-message">
              <i className="fas fa-hand-pointer fa-5x"></i>
              <p>Select a video on the left to view details</p>
            </div>
          ) : (
            <div className="default-message">
              <i className="fas fa-search fa-5x"></i>
              <p>{isLoading ? 'Searching...' : 'No videos found. Try a different search.'}</p>
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <div className="modal-backdrop">
          <div className="modal-content">
            <span className="modal-close" onClick={toggleModal}>
              &times;
            </span>
            <h3>Upload Video</h3>
            <input type="file" accept="video/*" className="modal-input" on />
            <button className="modal-save">Upload</button>
            <progress value={uploadProgress} max="100"></progress>
            <p>{uploadProgress}% uploaded</p>
          </div>
        </div>
      )}

      <Snackbar
        open={showArchiveSuccess}
        autoHideDuration={6000}
        onClose={handleCloseArchiveSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert onClose={handleCloseArchiveSuccess} severity="success" sx={{ width: '100%' }}>
          Video archived successfully!
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={showUnarchiveSuccess}
        autoHideDuration={6000}
        onClose={handleCloseUnarchiveSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert onClose={handleCloseUnarchiveSuccess} severity="success" sx={{ width: '100%' }}>
          Video unarchived successfully!
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default VideoManagement;

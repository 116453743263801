import RealTimeMap from './RealTimeMap';
import Dashboard from './Dashboard';
import { Link } from 'react-router-dom';
import NewDashboard from './NewDashboard/NewDashboard';
import BatteryMetrics from './BatteryMetrics';
import VideoManagement from './VideoManagement';
import Loading from './Loading';
import Configuration from './Configuration'; // Import the new configuration page
import AppAlertPopup from './AppAlertPopup';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useEffect, useState } from 'react';
import { AlertsProvider } from '../contexts/AlertsContext';
import { DeviceFilterProvider } from '../contexts/DeviceFilterContext';
import { getConfig } from '../services/SMConfigService';
import DeviceManagement from './DeviceManagement';
import { fetchRetentionPeriod } from '../services/SMConfigService';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './ErrorBoundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { isWithinInterval } from 'date-fns';

// Global variables for announcement
const ANNOUNCEMENT_TEXT = 'Device settings and assignment are now all in one place';
const ANNOUNCEMENT_DATE_RANGE = [new Date('2024-10-14T00:00:00'), new Date('2024-10-28T23:59:59')];
const ANNOUNCEMENT_LINK = '/settings';
let SHOW_ANNOUNCEMENT = isWithinInterval(new Date(), {
  start: ANNOUNCEMENT_DATE_RANGE[0],
  end: ANNOUNCEMENT_DATE_RANGE[1],
});

function AnnouncementBanner() {
  const location = useLocation();

  if (!SHOW_ANNOUNCEMENT || location.pathname === '/videos' || location.pathname === ANNOUNCEMENT_LINK) {
    return null;
  }

  return (
    <div className="floating-announcement-banner">
      <Link to={ANNOUNCEMENT_LINK} className="announcement-banner">
        <span className="new-label">NEW</span>
        {ANNOUNCEMENT_TEXT}
        <FontAwesomeIcon icon={faArrowRight} className="announcement-arrow" />
      </Link>
    </div>
  );
}

function App({ isAuthenticated, user, logout }) {
  const [config, setConfig] = useState(null);
  const [userSub, setUserSub] = useState(user.sub);
  const [featureMode, setFeatureMode] = useState('prod'); // prod | dev | demo
  const [error, setError] = useState(null); // State to manage errors
  const [retentionPeriodDays, setRetentionPeriodDays] = useState(30);
  const [showOutageAlert, setShowOutageAlert] = useState(false);

  useEffect(() => {
    let newFeatureMode = featureMode;
    let newUserSub = userSub;
    if (user.name.startsWith('plix') || user.name.startsWith('overwatch') || user.name.startsWith('bob@plix.ai')) {
      newFeatureMode = 'dev';
    }
    if (newFeatureMode === 'dev') {
      if (user.name.endsWith('cpgsecure.com')) {
        newUserSub = 'auth0|66623577e8564b8fb0940504'; // dan@cpgsecure.com
      }
    }
    if (user.name.startsWith('rgivens@cpgsecure.com') || user.name.startsWith('jvannatta@cpgsecure.com')) {
      newFeatureMode = 'prod';
      newUserSub = 'auth0|66623577e8564b8fb0940504'; // dan@cpgsecure.com
    }
    async function fetchConfig() {
      try {
        console.log('Fetching config for user:', newUserSub, ' name: ', user.name, ' feature mode: ', newFeatureMode);
        const configFromDB = await getConfig(newUserSub);
        const retentionPeriodDays = await fetchRetentionPeriod(newUserSub);
        console.log('Retention period fetched:', retentionPeriodDays);
        console.log('Config fetched:', configFromDB);
        setConfig(configFromDB);
        setRetentionPeriodDays(retentionPeriodDays);
      } catch (error) {
        console.error('Failed to fetch config:', error);
        setError('Your computer is offline. Please connect to Internet to use Plix.');
      }
    }
    fetchConfig();
    setFeatureMode(newFeatureMode);
    setUserSub(newUserSub);
    console.log('featureMode', featureMode);

    const outageAlertShown = localStorage.getItem('outageAlertShown');
    if (outageAlertShown !== 'true') {
      setShowOutageAlert(true);
      localStorage.setItem('outageAlertShown', 'true');
    }
  }, [user.sub]);

  const handleCloseOutageAlert = () => {
    setShowOutageAlert(false);
  };

  if (error) {
    return (
      <div className="error-container">
        <p className="error-message">{error}</p>
      </div>
    );
  }

  if (!config) {
    return <Loading />; // early return until config is loaded
  }

  // useEffect(() => {
  //   const viewport = document.querySelector('meta[name=viewport]');
  //   viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
  // }, []);

  return (
    <ErrorBoundary>
      <AlertsProvider user={user}>
        <Router>
          <div style={{ display: 'flex', overflow: 'clip' }}>
            <DeviceFilterProvider userId={userSub}>
              <Sidebar userName={user.name} userEmail={user.email} featureMode={featureMode} />
              <div className="main-content-area">
                <AnnouncementBanner />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <RealTimeMap
                        isAuthenticated={isAuthenticated}
                        logout={logout}
                        userId={userSub}
                        config={config}
                        setConfig={setConfig}
                      />
                    }
                  />
                  <Route path="olddashboard" element={<Dashboard userId={userSub} featureMode={featureMode} />} />
                  <Route
                    path="olddashboard/:escalation"
                    element={<Dashboard userId={userSub} featureMode={featureMode} />}
                  />
                  <Route
                    path="settings"
                    element={
                      <Configuration
                        config={config}
                        setConfig={setConfig}
                        userId={userSub}
                        featureMode={featureMode}
                        retentionPeriodDays={retentionPeriodDays}
                        isOverwatch={featureMode === 'dev' && user.name.startsWith('overwatch')}
                      />
                    }
                  />
                  <Route
                    path="videos"
                    element={
                      <VideoManagement
                        isAuthenticated={isAuthenticated}
                        logout={logout}
                        userId={userSub}
                        retentionPeriodDays={retentionPeriodDays}
                      />
                    }
                  />
                  <Route path="dashboard" element={<NewDashboard userId={userSub} />} />
                  {featureMode === 'dev' && (
                    <Route path="batterymetrics" element={<BatteryMetrics userId={userSub} />} />
                  )}
                  <Route
                    path="configuration"
                    element={
                      <Configuration
                        config={config}
                        setConfig={setConfig}
                        userId={userSub}
                        retentionPeriodDays={retentionPeriodDays}
                        isOverwatch={featureMode === 'dev' && user.name.startsWith('overwatch')}
                      />
                    }
                  />
                  {featureMode === 'dev' && (
                    <Route path="device-management" element={<DeviceManagement userId={userSub} />} />
                  )}
                </Routes>
              </div>
            </DeviceFilterProvider>
          </div>
          {/* <AppAlertPopup open={showOutageAlert} onClose={handleCloseOutageAlert} /> */}
          <ToastContainer />
        </Router>
      </AlertsProvider>
    </ErrorBoundary>
  );
}

export default App;
